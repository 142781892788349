import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Switch, Rate, Select, Upload, message } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { axiosInstance } from '../API/authapi';

const options = [
  { label: 'Top rated', value: 'top' },
  { label: 'Photographers', value: 'photographer' },
  { label: 'Eco friendly', value: 'eco' },
  { label: 'Group', value: 'group' },
  { label: 'Sells fast', value: 'sells' },
  { label: 'Cultural', value: 'cultural' },
  { label: 'Local', value: 'local' },
];

const TripModal = ({ visible, onClose, booking, onSave, bookingIdentifiers }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableBooking, setEditableBooking] = useState(booking);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const relatedTripsOptions = bookingIdentifiers.map((booking) => ({
    label: booking.tripname, // Display tripname
    value: booking._id, // Pass _id as the value
  }));

  const handleDelete = () => {
    // Show confirmation before deletion
    Modal.confirm({
      title: 'Are you sure you want to delete this trip?',
      content: 'This action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No, Cancel',
      onOk() {
        // Perform the delete operation
        deleteTrip(editableBooking._id); // Assuming each trip has a unique id
      },
    });
  };

  const deleteTrip = async (tripId) => {
    try {
      // Perform the delete request
      const response = await axiosInstance.delete(`/api/trips/${tripId}`);
      console.log('Trip deleted successfully:', response.data);

      // Notify the parent component that the trip has been deleted
      onSave(null); // Pass null to indicate deletion

      // Clear the editable booking state
      setEditableBooking(null);

      // Exit edit mode and close the modal
      setIsEditMode(false);
      onClose();
    } catch (error) {
      console.error('Error deleting trip:', error);
      // Optionally, you can handle the error here, e.g., show a message to the user
    }
  };



  const handleChange = (field, value) => {
    console.log('fromHandleChange', field, value);

    setEditableBooking((prevBooking) => ({
      ...prevBooking,
      [field]: value,
    }));

    console.log(editableBooking);

  };

  const handleRelatedTripsChange = (selectedValues) => {
    setEditableBooking((prevBooking) => ({
      ...prevBooking,
      related_trips: selectedValues, // Store the selected _id values
    }));
  };

  const handleAddInput = (field) => {
    setEditableBooking(prevState => ({
      ...prevState,
      [field]: [...(prevState[field] || []), ''],
    }));
  };


  const handleRemoveInput = (field, index) => {
    const updatedArray = editableBooking[field].filter((_, i) => i !== index);
    setEditableBooking(prevState => ({ ...prevState, [field]: updatedArray }));
  };

  const handleArrayInputChange = (field, index, value) => {
    const updatedArray = [...editableBooking[field]];
    updatedArray[index] = value;
    setEditableBooking(prevState => ({ ...prevState, [field]: updatedArray }));
  };



  const handleSave = async () => {
    try {
      const formData = new FormData();

      // Prepare formData from editableBooking
      Object.keys(editableBooking).forEach(key => {
        // Skip empty age fields
        if ((key === 'min_age' || key === 'max_age') &&
          (editableBooking[key] === '' || editableBooking[key] === null || editableBooking[key] === undefined)) {
          return;
        }

        if (Array.isArray(editableBooking[key])) {
          editableBooking[key].forEach((item, index) => {
            if (key === 'photos' && item.originFileObj) {
              formData.append('photos', item.originFileObj);
            } else {
              formData.append(`${key}`, item);
            }
          });
        } else if (typeof editableBooking[key] === 'object' && editableBooking[key] !== null && key === 'thumbnail') {
          formData.append(`${key}`, editableBooking[key]);
        } else if (typeof editableBooking[key] === 'object' && editableBooking[key] !== null) {
          Object.keys(editableBooking[key]).forEach(subKey => {
            formData.append(`${key}[${subKey}]`, editableBooking[key][subKey]);
          });
        } else if (editableBooking[key] !== null && editableBooking[key] !== undefined && editableBooking[key] !== '') {
          formData.append(key, editableBooking[key]);
        }
      });

      // Update existing trip
      await axiosInstance.put(`/api/trips/${editableBooking._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success("Updated")

      onSave(editableBooking); // Pass updated booking data to parent save function
      setIsEditMode(false); // Exit edit mode
      onClose(); // Close the modal
    } catch (error) {
      message.error(error)
      console.error('Failed to save trip', error);
    }
  };

  const handleNestedInputChange = (parentField, field, value) => {
    setEditableBooking(prevState => {
      const updatedState = {
        ...prevState,
        [parentField]: { ...prevState[parentField], [field]: value },
      };
      console.log("Updated state:", updatedState);
      return updatedState;
    });
  };


  const checksvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
      <path fill="blue" fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
    </svg>
  );

  const notchecksvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
      <path fill="orange" fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14m2.78-4.22a.75.75 0 0 1-1.06 0L8 9.06l-1.72 1.72a.75.75 0 1 1-1.06-1.06L6.94 8L5.22 6.28a.75.75 0 0 1 1.06-1.06L8 6.94l1.72-1.72a.75.75 0 1 1 1.06 1.06L9.06 8l1.72 1.72a.75.75 0 0 1 0 1.06" clipRule="evenodd" />
    </svg>
  );
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [imageList, setImageList] = useState(editableBooking.photos || []);
  const [thumbImg, setThumbImg] = useState(editableBooking.thumImg || []);

  const handlePreview = (file) => {
    console.log('forPreview', file);

    setPreviewImage(file.url || file.thumbUrl);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleThumbnail = () => {
    // Perform the action to delete the thumbnail, e.g., setting a new state or making an API call
    // Example: remove thumbnail from editableBooking and update state
    setEditableBooking((prevBooking) => ({
      ...prevBooking,
      mainphoto: null // or update to the desired state
    }));
    setThumbImg([])
  };


  const handleDeleteImage = (index) => {
    const updatedImages = [...imageList];

    updatedImages.splice(index, 1);
    console.log('newupdated', updatedImages)
    setImageList([...updatedImages]);
    handleChange('photos', [...updatedImages]);
  };

  const handleImageUpload = (file) => {
    console.log('from hanld image', file);

    // const newImage = URL.createObjectURL(file);
    const updatedImages = [...imageList, file];
    console.log(updatedImages);

    setImageList(updatedImages);
    handleChange('photos', updatedImages);
    return false; // Prevent default upload behavior
  };

  const [categories, setCategories] = useState([]);
  const sortedcategories = categories.map((booking) => ({
    label: booking.category, // Display tripname
    value: booking.category, // Pass _id as the value
  }));


  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get('/api/category/all', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setCategories(response.data);
    } catch (error) {
      message.error('Failed to fetch categories');
    }
  };

  const handleAddThumbnail = () => {
    // Create an input element dynamically
    const fileInput = document.createElement('input');

    // Set the type to 'file' and accept only image files
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    // Add an event listener to handle the file once it's selected
    fileInput.onchange = (event) => {
      const file = event.target.files[0]; // Get the selected file
      if (file) {
        handleChange('thumbnail', file)
      }
    };

    // Programmatically trigger the file input
    fileInput.click();
  };



  return (
    <Modal visible={visible} onCancel={onClose} footer={null} width={1000}>
      {booking && (
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-4">#{editableBooking.tripname}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="col-span-2 grid grid-cols-2 md:grid-cols-4 gap-2 ">
              <div className=' relative'>
                <Button onClick={handleAddThumbnail} className=' absolute cursor-default top-1 left-1 '>Thumbnail</Button>
                {editableBooking.mainphoto && <img
                  src={`https://tripcurascobackend-production.up.railway.app/${editableBooking.mainphoto}`}
                  // alt={`Image ${index + 1}`}
                  className="rounded-md w-full h-40 object-cover"
                />}

                {isEditMode && (
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={handleThumbnail}
                    className="absolute top-0 right-0"
                  />
                )}
              </div>
              {editableBooking.photos.map((image, index) => (
                <div key={index} className="relative">
                  <img
                    src={`https://tripcurascobackend-production.up.railway.app/${image}`}
                    alt={`Image ${index + 1}`}
                    className="rounded-md w-full h-40 object-cover"
                  />
                  {isEditMode && (
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteImage(index)}
                      className="absolute top-0 right-0 z-10"
                    />
                  )}
                </div>
              ))}

              {/* Separate Upload Button */}
              {isEditMode && editableBooking.photos.length < 30 && (
                <div className="flex items-center justify-center h-40 border rounded-md">
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={handleImageUpload}

                  >

                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>

                  </Upload>
                </div>
              )}
            </div>
            <div>


              {/* <div className="mb-2">
                <span className="font-semibold">API key:</span>
                {isEditMode ? (
                  <Input value={editableBooking.API} onChange={(e) => handleChange('API', e.target.value)} />
                ) : (
                  editableBooking.API
                )}

              </div> */}
              <div className="mb-2">
                <span className="font-semibold">Tripname:</span>
                {isEditMode ? (
                  <div>
                    <Input
                      placeholder="Trip Name"
                      value={editableBooking.tripname}
                      onChange={(e) => handleChange('tripname', e.target.value)}
                    />

                  </div>
                ) : (
                  `${editableBooking.tripname}`
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Price:</span>
                {isEditMode ? (
                  <Input value={editableBooking.price} onChange={(e) => handleChange('price', e.target.value)} />
                ) : (
                  `$${editableBooking.price}`
                )}
              </div>
              <div className='mb-2'>
                <span className="font-semibold">Discount:</span>
                {isEditMode ? (
                  <Input value={editableBooking.discount} onChange={(e) => handleChange('discount', e.target.value)} />
                ) : (
                  `$${editableBooking.discount}`
                )}
              </div>
              <div className='mb-2'>
                <span className="font-semibold">ServiceFee:</span>
                {isEditMode ? (
                  <Input value={editableBooking.servicefee} onChange={(e) => handleChange('servicefee', e.target.value)} />
                ) : (
                  `$${editableBooking.servicefee}`
                )}
              </div>
              <div className='mb-2'>
                <span className="font-semibold">Location:</span>
                {isEditMode ? (
                  <Input value={editableBooking.location} onChange={(e) => handleChange('location', e.target.value)} />
                ) : (
                  `$${editableBooking.location}`
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Age:</span>
                {isEditMode ? (
                  <div>
                    <Input
                      placeholder="Min Age"
                      value={editableBooking.min_age}
                      onChange={(e) => handleChange('min_age', e.target.value)}
                      style={{ width: '48%', marginRight: '4%' }}
                    />
                    <Input
                      placeholder="Max Age"
                      value={editableBooking.max_age}
                      onChange={(e) => handleChange('max_age', e.target.value)}
                      style={{ width: '48%' }}
                    />
                  </div>
                ) : (
                  `${editableBooking.min_age} - ${editableBooking.max_age}`
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Latitude:</span>
                {isEditMode ? (
                  <Input value={editableBooking.latitude} onChange={(e) => handleChange('latitude', e.target.value)} />
                ) : (
                  editableBooking.latitude
                )}

              </div>
              <div className="mb-2">
                <span className="font-semibold">Longitude:</span>
                {isEditMode ? (
                  <Input value={editableBooking.longitude} onChange={(e) => handleChange('longitude', e.target.value)} />
                ) : (
                  editableBooking.longitude
                )}

              </div>
              <div className="mb-2">
                <span className="font-semibold">Description:</span>
                {isEditMode ? (
                  <Input.TextArea value={editableBooking.Discription} onChange={(e) => handleChange('Discription', e.target.value)} />
                ) : (
                  editableBooking.Discription
                )}

              </div>
              <div className="mb-2">
                <span className="font-semibold">Category:</span>
                {isEditMode ? (
                  <Select
                    mode="multiple"
                    placeholder="Category"
                    value={editableBooking.category}
                    style={{ width: '100%' }}
                    options={sortedcategories}
                    onChange={(value) => handleChange('category', value)}

                  />) : (
                    Array.isArray(editableBooking.category) && editableBooking.category.map((category, index) => (
                      <p>{category}</p>
                    ))
                  )}
                 
              </div>
              <div className="mb-2">
                <span className="font-semibold">Company:</span>
                {isEditMode ? (
                  <Input value={editableBooking.company} onChange={(e) => handleChange('company', e.target.value)} />
                ) : (
                  `${editableBooking.company}`
                )}
              </div>
              {/* <div className="mb-2">
                <span className="font-semibold">API key:</span>
                {isEditMode ? (
                  <Input value={editableBooking.API} onChange={(e) => handleChange('API', e.target.value)} />
                ) : (
                  editableBooking.API
                )}

                </div> */}


              <div className="mb-2">
                <span className="font-semibold">Time duration:</span>
                {isEditMode ? (
                  <Input value={editableBooking.duration} onChange={(e) => handleChange('duration', e.target.value)} />
                ) : (
                  editableBooking.duration
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Badges:</span>
                {isEditMode ? (
                  <Select
                    mode="tags"
                    placeholder="Please select"
                    value={editableBooking.badge}
                    onChange={(value) => handleChange('badge', value)}
                    style={{ width: '100%' }}
                    options={options}
                  />
                ) : (
                  Array.isArray(editableBooking.badge) && editableBooking.badge.map((badge, index) => (
                    <img key={index} src={`../../assets/svg/allbadges/${badge}.svg`} alt="badge" className="inline-block h-6 mr-2" />
                  ))
                )}
              </div>


              <div className="mb-2">
                <span className="font-semibold">Related trips:</span>
                {isEditMode ? (
                  <Select
                    mode="multiple"
                    placeholder="Please select"
                    value={editableBooking.related_trips}
                    onChange={handleRelatedTripsChange}
                    style={{ width: '100%' }}
                    options={relatedTripsOptions}
                  />
                ) : (
                  Array.isArray(editableBooking.related_trips) && editableBooking.related_trips.map((relatedTrips, index) => (
                    // <img key={index} src={badge} alt="badge" className="inline-block h-6 mr-2" />
                    <p>{relatedTrips}</p>
                  ))
                )}
              </div>
              <div className="mb-2 flex">
                <span className="font-semibold">Pickup:</span>
                {isEditMode ? (
                  <Switch
                    checked={editableBooking.pickup}
                    onChange={(checked) => handleChange('pickup', checked)}
                  />
                ) : (
                  <span className="text-blue-500">
                    {editableBooking.pickup ? checksvg : notchecksvg}
                  </span>
                )}
              </div>
              {editableBooking.pickup ? <div className="mb-2">
                <span className="font-semibold">Pickup price:</span>
                {isEditMode ? (
                  <Input value={editableBooking.pickupPrice} onChange={(e) => handleChange('pickupPrice', e.target.value)} />
                ) : (
                  `$${editableBooking.pickupPrice}`
                )}

              </div> : ''
              }
              {editableBooking.pickup ?
                <div className="mb-2">
                  <span className="font-semibold">Pickup direction:</span>
                  {isEditMode ? (
                    <Input value={editableBooking.pickupDirection} onChange={(e) => handleChange('pickupDirection', e.target.value)} />
                  ) : (
                    editableBooking.pickupDirection
                  )}

                </div> : ''
              }

              <div className="mb-2 flex">
                <span className="font-semibold">API:</span>
                {isEditMode ? (
                  <Switch
                    checked={editableBooking.api_integration.enabled}
                    onChange={(checked) =>
                      handleNestedInputChange('api_integration', 'enabled', checked)
                    }
                  />
                ) : (
                  <span className="text-blue-500">
                    {editableBooking.api_integration.enabled ? checksvg : notchecksvg}
                  </span>
                )}
              </div>

              {editableBooking.api_integration.enabled && (
                <>
                  <div className="mb-2 flex">
                    <span className="font-semibold">Fetch price from API:</span>
                    {isEditMode ? (
                      <Switch
                        checked={editableBooking.api_integration.fetch_price}
                        onChange={(checked) =>
                          handleNestedInputChange('api_integration', 'fetch_price', checked)
                        }
                      />
                    ) : (
                      <span className="text-blue-500">
                        {editableBooking.api_integration.fetch_price ? checksvg : notchecksvg}
                      </span>
                    )}
                  </div>

                  <div className="mb-2">
                    <span className="font-semibold">API key:</span>
                    {isEditMode ? (
                      <Input
                        value={editableBooking.api_integration.key}
                        onChange={(e) =>
                          handleNestedInputChange('api_integration', 'key', e.target.value)
                        }
                      />
                    ) : (
                      editableBooking.api_integration.key
                    )}
                  </div>

                  <div className="mb-2">
                    <span className="font-semibold">API Secret:</span>
                    {isEditMode ? (
                      <Input
                        value={editableBooking.api_integration.secret}
                        onChange={(e) =>
                          handleNestedInputChange('api_integration', 'secret', e.target.value)
                        }
                      />
                    ) : (
                      editableBooking.api_integration.secret
                    )}
                  </div>

                  <div className="mb-2">
                    <span className="font-semibold">API ID:</span>
                    {isEditMode ? (
                      <Input
                        value={editableBooking.api_integration.url}
                        onChange={(e) => handleNestedInputChange('api_integration', 'url', e.target.value)}
                      />
                    ) : (
                      editableBooking.api_integration.url
                    )}
                  </div>
                </>
              )}
            </div>

            <div>

              <div className="mb-2">
                <span className="font-semibold">About This Activity:</span>
                {isEditMode ? (
                  <Input.TextArea value={editableBooking.about} onChange={(e) => handleChange('about', e.target.value)} />
                ) : (
                  <p>{editableBooking.about}</p>
                )}
              </div>
              {/* <div className="mb-2">
                <span className="font-semibold">Highlights:</span>
                {isEditMode ? (
                  <Input.TextArea value={editableBooking.highlights || ''} onChange={(e) => handleChange('highlights', e.target.value)} />
                ) : (
                  <ul className="list-disc pl-5">
                    {editableBooking.highlights?.split(',').map((highlight, index) => <li key={index}>{highlight}</li>)}
                  </ul>
                )}
              </div> */}
              <div className="mb-2">
                <span className="font-semibold">Do this because:</span>
                {isEditMode ? (
                  <ul className="list-disc pl-5">
                    {editableBooking.do_this_because?.map((item, index) => (
                      <li key={index} className="flex items-center mt-1">
                        <Input
                          value={item || ''}
                          onChange={(e) => handleArrayInputChange('do_this_because', index, e.target.value)}
                          className="flex-grow"
                        />
                        <Button
                          type="link"
                          onClick={() => handleRemoveInput('do_this_because', index)}
                          icon={<DeleteOutlined />}
                        />
                      </li>
                    ))}

                    <Button onClick={() => handleAddInput('do_this_because')} className='bg-gray-400 mt-1'>Add Item</Button>

                  </ul>
                ) : (
                  <ul className="list-disc pl-5">
                    {editableBooking.do_this_because?.map((item, index) => <li key={index}>{item}</li>)}
                  </ul>
                )}
              </div>

              <div className="mb-2">
                <span className="font-semibold">Insights:</span>
                {isEditMode ? (
                  <Input.TextArea value={editableBooking.insights || ''} onChange={(e) => handleChange('insights', e.target.value)} />
                ) : (
                  <p>{editableBooking.insights}</p>
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Know before you go:</span>
                {isEditMode ? (
                  <ul className="list-disc pl-5">
                    {editableBooking.know_before_you_go?.map((item, index) => (
                      <li key={index} className="flex items-center mt-1">
                        <Input
                          value={item || ''}
                          onChange={(e) => handleArrayInputChange('know_before_you_go', index, e.target.value)}
                          className="flex-grow"
                        />
                        <Button
                          type="link"
                          onClick={() => handleRemoveInput('know_before_you_go', index)}
                          icon={<DeleteOutlined />}
                        />
                      </li>
                    ))}

                    <Button onClick={() => handleAddInput('know_before_you_go')} className="bg-gray-400 mt-1">
                      Add Item
                    </Button>
                  </ul>
                ) : (
                  <ul className="list-disc pl-5">
                    {editableBooking.know_before_you_go?.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
              </div>


            </div>
            <div className="mb-2 flex">
              <span className="font-semibold">Immediate booking:</span>
              {isEditMode ? (
                <Switch
                  checked={editableBooking.immediate_booking}
                  onChange={(checked) => handleChange('immediate_booking', checked)}
                />
              ) : (
                <span className="text-blue-500">
                  {editableBooking.immediate_booking ? checksvg : notchecksvg}
                </span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
            <div className='border border-theblue-400 rounded-md p-3'>
              <div className="font-semibold mb-2">Suitable for:</div>
              <div className="grid grid-cols-1 gap-2">
                {['suitable_for_single', 'suitable_for_couple', 'suitable_for_children', 'suitable_for_group'].map((key) => (
                  <div key={key} className="flex items-center">
                    <span className="mr-2">
                      {key.replace('suitable_for_', '').replace(/_/g, ' ').charAt(0).toUpperCase() +
                        key.replace('suitable_for_', '').replace(/_/g, ' ').slice(1)}:
                    </span>
                    {isEditMode ? (
                      <Switch
                        size='small'
                        checked={editableBooking[key]}
                        onChange={(checked) => handleChange(key, checked)}
                      />
                    ) : (
                      <span>{editableBooking[key] ? checksvg : notchecksvg}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className='border border-theblue-400 rounded-md p-3'>
              <div className="font-semibold mb-2">Included items:</div>

              {isEditMode ? (
                <ul className="list-disc ">
                  {editableBooking.include?.map((item, index) => (
                    <li key={index} className="flex items-center mt-1">
                      <Input
                        value={item || ''}
                        onChange={(e) => handleArrayInputChange('include', index, e.target.value)}
                        className="flex-grow"
                      />
                      <Button
                        type="link"
                        onClick={() => handleRemoveInput('include', index)}
                        icon={<DeleteOutlined />}
                      />
                    </li>
                  ))}

                  <Button onClick={() => handleAddInput('include')} className='bg-gray-400 mt-1'>Add Item</Button>

                </ul>
              ) : (
                <ul className="list-disc pl-5">
                  {editableBooking.include?.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
              )}

            </div>

            <div className='border border-theblue-400 rounded-md p-3'>
              <div className="font-semibold mb-2">Present items:</div>
              {isEditMode ? (
                <ul className="list-disc ">
                  {editableBooking.present?.map((item, index) => (
                    <li key={index} className="flex items-center mt-1">
                      <Input
                        value={item || ''}
                        onChange={(e) => handleArrayInputChange('present', index, e.target.value)}
                        className="flex-grow"
                      />
                      <Button
                        type="link"
                        onClick={() => handleRemoveInput('present', index)}
                        icon={<DeleteOutlined />}
                      />
                    </li>
                  ))}

                  <Button onClick={() => handleAddInput('present')} className='bg-gray-400 mt-1'>Add Item</Button>

                </ul>
              ) : (
                <ul className="list-disc pl-5">
                  {editableBooking.present?.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
              )}
            </div>

            <div className='border border-theblue-400 rounded-md p-3'>
              <div className="font-semibold mb-2">Available languages:</div>
              <div className="grid grid-cols-1 gap-2">
                {['languages_english', 'languages_dutch', 'languages_spanish', 'languages_papiamentu'].map((key) => (
                  <div key={key} className="flex items-center">
                    <span className="mr-2">{key.replace('languages_', '').replace(/\b\w/g, char => char.toUpperCase())}:</span>
                    {isEditMode ? (
                      <Switch
                        size='small'
                        checked={editableBooking[key]}
                        onChange={(checked) => handleChange(key, checked)}
                      />
                    ) : (
                      <span>{editableBooking[key] ? checksvg : notchecksvg}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div>
              <label className="font-semibold mb-2">What to bring:</label>
              {isEditMode ? (
                <ul className="list-disc ">
                  {editableBooking.what_to_bring?.map((item, index) => (
                    <li key={index} className="flex items-center mt-1">
                      <Input
                        value={item || ''}
                        onChange={(e) => handleArrayInputChange('what_to_bring', index, e.target.value)}
                        className="flex-grow"
                      />
                      <Button
                        type="link"
                        onClick={() => handleRemoveInput('what_to_bring', index)}
                        icon={<DeleteOutlined />}
                      />
                    </li>
                  ))}

                  <Button onClick={() => handleAddInput('what_to_bring')} className='bg-gray-400 mt-1'>Add Item</Button>

                </ul>
              ) : (
                <ul className="list-disc pl-5">
                  {editableBooking.what_to_bring?.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
              )}

            </div>
          </div>

          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={handleEditClick} className="mr-2">
              {isEditMode ? 'Cancel' : 'Edit'}
            </Button>
            <Button type="danger" onClick={handleDelete}>
              Delete
            </Button>
            {isEditMode && (
              <Button type="primary" onClick={handleSave}>
                Save
              </Button>
            )}
          </div>

        </div>
      )}
    </Modal>
  );
};

export default TripModal;