import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, notification,message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../API/authapi';
// import 'antd/dist/antd.css';

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token ) {
      message.warning('Already Logged In. ');
      navigate('/admin'); // Redirect to home if the token is valid
    }
  }, [navigate]);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const response = await axiosInstance.post('/api/admin/login', values);
      localStorage.setItem('token', response.data.token); // Store token in local storage
      message.success('Logged in successfully');
      setLoading(false);
      navigate('/admin'); // Redirect to a home page after login
    } catch (error) {
      message.error(error.response.data.message || 'Login failed');
      setLoading(false);
    }
  };




  return (
    <main className=' bg-gray-100 items-center'>
      
      <div className="flex flex-col justify-center items-center min-h-screen gap-10">
      <a href='/' className='logo text-5xl text-theblue-400'>TheTrip <br />Curaçao</a>
        <Form
          name="admin_login"
          className="w-full max-w-sm bg-white p-8 rounded-lg shadow-md"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              className="w-full py-2 px-3 border rounded"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              className="w-full py-2 px-3 border rounded"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              loading={loading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </main>
  );
};

export default AdminLogin;
