import React, { useState, useEffect } from 'react';
import { Modal, Switch, Input, Button, Upload, Select, Space, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { MinusCircleOutlined } from '@ant-design/icons';
import { axiosInstance } from '../API/authapi';

const options = [
  { label: 'Top rated', value: 'top' },
  { label: 'Photographers', value: 'photographer' },
  { label: 'Eco friendly', value: 'eco' },
  { label: 'Group', value: 'group' },
  { label: 'Sells fast', value: 'sells' },
  { label: 'Cultural', value: 'cultural' },
  { label: 'Local', value: 'local' },
];


const AddTripModal = ({ visible, onClose, onSave, bookingIdentifiers }) => {

  const [geoModalVisible, setGeoModalVisible] = useState(false);


  const relatedTripsOptions = bookingIdentifiers.map((booking) => ({
    label: booking.tripname, // Display tripname
    value: booking._id, // Pass _id as the value
  }));
  const [categories, setCategories] = useState([]);
  const sortedcategories = categories.map((booking) => ({
    label: booking.category, // Display tripname
    value: booking.category, // Pass _id as the value
  }));


  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get('/api/category/all', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setCategories(response.data);
    } catch (error) {
      message.error('Failed to fetch categories');
    }
  };
  const initialTripData = {
    tripname: '',
    API: '',
    APIkey: '',
    APIurl: '',
    fetchAAPI: '',
    price: '',
    discount: '',
    servicefee: '',
    min_age: '',
    max_age: '',
    location: '',
    category: [],
    company: '',
    duration: '',
    longitude: '',
    latitude: '',
    badge: [],
    pickup: false,
    pickupPrice: '',
    Discription: '',
    pickupDirection: '',
    about: '',
    do_this_because: [''],
    insights: '',
    know_before_you_go: [''],
    present: [''],
    suitable_for: { single: false, couple: false, children: false, group: false },
    include: [''],
    languages: { english: false, dutch: false, spanish: false, papiamentu: false },
    what_to_bring: [''],
    immediate_booking: false,
    api_integration: {
      enabled: false,
      url: '',
      key: '',
      secret: '',
      fetch_price: false,
    },
    related_trips: [],
    photos: [],
  };
  const [api, setApi] = useState(false)
  const [tripData, setTripData] = useState(initialTripData);

  const handleInputChange = (field, value) => {
    setTripData(prevState => ({ ...prevState, [field]: value }));
  };

  const handleNestedInputChange = (parentField, field, value) => {
    setTripData(prevState => ({
      ...prevState,
      [parentField]: { ...prevState[parentField], [field]: value },
    }));
  };



  const handleAddInput = (field) => {
    setTripData(prevState => ({
      ...prevState,
      [field]: [...prevState[field], ''],
    }));
  };

  const handleRemoveInput = (field, index) => {
    const updatedArray = tripData[field].filter((_, i) => i !== index);
    setTripData(prevState => ({ ...prevState, [field]: updatedArray }));
  };
  const handleArrayInputChange = (field, index, value) => {
    const updatedArray = [...tripData[field]];
    updatedArray[index] = value;
    setTripData(prevState => ({ ...prevState, [field]: updatedArray }));
  };

  const handleBadgeChange = value => {
    setTripData(prevState => ({ ...prevState, badge: value }));
  };

  const handleRelatedTripsChange = (selectedValues) => {
    setTripData((prevData) => ({
      ...prevData,
      related_trips: selectedValues, // Store the selected _id values
    }));
  };

  const handleCategoryChange = (selectedValues) => {
    setTripData((prevData) => ({
      ...prevData,
      category: selectedValues, // Store the selected _id values
    }));
  };

  const validateFields = () => {
    const errors = [];

    // Required fields
    const requiredFields = [
      'tripname', 'price', 'company', 'duration',
      'servicefee', 'Discription', 'insights'
    ];
    requiredFields.forEach(field => {
      if (!tripData[field]) {
        errors.push(`${field.charAt(0).toUpperCase() + field.slice(1)} is required`);
      }
    });

    // Numeric fields
    const numericFields = ['price', 'discount', 'servicefee', 'min_age', 'max_age'];
    numericFields.forEach(field => {
      if (tripData[field] && isNaN(Number(tripData[field]))) {
        errors.push(`${field.charAt(0).toUpperCase() + field.slice(1)} must be a number`);
      }
    });

    // Age validation
    if (Number(tripData.min_age) > Number(tripData.max_age)) {
      errors.push('Minimum age cannot be greater than maximum age');
    }

    // Pickup validation
    if (tripData.pickup) {
      if (!tripData.pickupPrice) {
        errors.push('Pickup price is required when pickup is enabled');
      }
      if (!tripData.pickupDirection) {
        errors.push('Pickup direction is required when pickup is enabled');
      }
    }

    // API Integration validation
    if (tripData.api_integration.enabled) {
      ['url', 'key', 'secret'].forEach(field => {
        if (!tripData.api_integration[field]) {
          errors.push(`API ${field} is required when API integration is enabled`);
        }
      });
    }

    // Array field validations
    ['do_this_because', 'know_before_you_go'].forEach(field => {
      if (tripData[field].some(item => !item.trim())) {
        errors.push(`All ${field.replace(/_/g, ' ')} items must be filled`);
      }
    });

    // Image validation
    if (!tripData.thumbnail) {
      errors.push('Thumbnail image is required');
    }

    if (tripData.photos.length === 0) {
      errors.push('At least one additional image is required');
    }

    return errors;
  };

  const handleSave = async () => {
    const validationErrors = validateFields();
    if (validationErrors.length > 0) {
      validationErrors.forEach(error => message.error(error));
      return;
    }
    console.log(tripData);

    try {
      const formData = new FormData();

      Object.keys(tripData).forEach(key => {
        if (key === 'thumbnail' && tripData[key]?.originFileObj) {
          formData.append('thumbnail', tripData[key].originFileObj);
        } else if (Array.isArray(tripData[key])) {
          tripData[key].forEach((item, index) => {
            if (typeof item === 'object' && item.originFileObj) {
              formData.append('photos', item.originFileObj);
            } else {
              formData.append(`${key}[${index}]`, item);
            }
          });
        } else if (typeof tripData[key] === 'object' && tripData[key] !== null) {
          // Handle nested objects like `api_integration`
          Object.keys(tripData[key]).forEach(subKey => {
            formData.append(`${key}[${subKey}]`, tripData[key][subKey]);
          });
        } else {
          formData.append(key, tripData[key]);
        }
      });

      // Inspect FormData contents
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }
      console.log(formData);

      // Send formData
      await axiosInstance.post('/api/trips', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Handle success
      onSave({ ...tripData, id: Date.now() });
      setTripData(initialTripData); // Reset form
      message.success(`Trip Added`);
      onClose();
    } catch (error) {
      const errorMessage = error.response?.data?.error || "An unexpected error occurred";
      message.error(`Failed to save trip: ${errorMessage}`);
      console.error('Failed to save trip', error);
    }

  };



  const [knowBeforeInputs, setKnowBeforeInputs] = useState(['']);
  const [doThisInputs, setDoThisInputs] = useState(['']);
  const [presentInputs, setpresentInputs] = useState(['']);
  const [includedInputs, setincludedInputs] = useState(['']);
  const maxInputs = 4;

  // const handleInputChange = (index, value) => {
  //   const newInputs = [...inputs];
  //   newInputs[index] = value;
  //   setInputs(newInputs);
  // };
  const addKnowBeforeInput = () => {
    if (knowBeforeInputs.length < maxInputs) {
      setKnowBeforeInputs([...knowBeforeInputs, '']);

    }
  };

  const addDoThisInput = () => {
    if (doThisInputs.length < maxInputs) {

      setDoThisInputs([...doThisInputs, '']);

    }
  };

  const removeKnowBeforeInput = (index) => {
    const newInputs = knowBeforeInputs.filter((_, i) => i !== index);
    setKnowBeforeInputs(newInputs);
  };

  const removeDoThisInput = (index) => {
    console.log("here");

    const newInputs = doThisInputs.filter((_, i) => i !== index);
    console.log(newInputs);

    setDoThisInputs(newInputs);
  };

  const [pickup, setPickup] = useState(false);
  const [pickupPrice, setPickupPrice] = useState('');
  const [pickupDirection, setPickupDirection] = useState('');

  const handlePickupChange = (checked) => {
    setPickup(checked);
  };
  const handleApiChange = (checked) => {
    setApi(checked);
  };


  useEffect(() => {
    if (visible) {
      setTripData(initialTripData);
    }
  }, [visible]);



  return (
    <Modal title="Add Trip" visible={visible} onCancel={onClose} footer={null} width={1200}>
      <div className="grid grid-cols-2 gap-8">
        {/* Left Column */}
        <div className="flex flex-col gap-4">
          <div>
            {/* Thumbnail Image Upload */}
            <div>
              <h4>Thumbnail Image</h4>
              <Upload
                listType="picture-card"
                fileList={tripData.thumbnail ? [tripData.thumbnail] : []}
                onChange={({ fileList }) => handleInputChange('thumbnail', fileList[0])}
                beforeUpload={() => false}
                maxCount={1} // Ensures only one thumbnail image can be uploaded
              >
                {!tripData.thumbnail && (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload Thumbnail</div>
                  </div>
                )}
              </Upload>
            </div>

            {/* Other Images Upload */}
            <div style={{ marginTop: 16 }}>
              <h4>Additional Images</h4>
              <Upload
                listType="picture-card"
                fileList={tripData.images}
                onChange={({ fileList }) => handleInputChange('photos', fileList)}
                beforeUpload={() => false}
                multiple
              >
                {tripData?.photos && (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}

              </Upload>
            </div>
          </div>

          <Input
            placeholder="Trip name"
            value={tripData.tripname}
            onChange={e => handleInputChange('tripname', e.target.value)}
          />
          <Input
            placeholder="Price"
            value={tripData.price}
            onChange={e => handleInputChange('price', e.target.value)}
          />
          <Input
            placeholder="Discount"
            value={tripData.discount}
            onChange={e => handleInputChange('discount', e.target.value)}
          />
          <Input
            placeholder="Servicefee"
            value={tripData.servicefee}
            onChange={e => handleInputChange('servicefee', e.target.value)}
          />
          <Input
            placeholder="Location"
            value={tripData.location}
            onChange={e => handleInputChange('location', e.target.value)}
          />
          <div className="flex gap-2">
            <Input
              placeholder="Min Age"
              value={tripData.min_age}
              onChange={e => handleInputChange('min_age', e.target.value)}
            />
            <Input
              placeholder="Max Age"
              value={tripData.max_age}
              onChange={e => handleInputChange('max_age', e.target.value)}
            />
          </div>
          <Input
            placeholder="Latitude"
            value={tripData.latitude}
            onChange={e => handleInputChange('latitude', e.target.value)}

          />

          <Input
            placeholder="Longitude"
            value={tripData.longitude}
            onChange={e => handleInputChange('longitude', e.target.value)}

          />

          <Input.TextArea
            placeholder="Description"
            value={tripData.Discription}
            onChange={e => handleInputChange('Discription', e.target.value)}
          />
          <Select
            mode="multiple"
            placeholder="Category"
            value={tripData.category}
            options={sortedcategories}
            onChange={handleCategoryChange}

          />
          <Input
            placeholder="Company"
            value={tripData.company}
            onChange={e => handleInputChange('company', e.target.value)}
          />
          <Input
            placeholder="Duration"
            value={tripData.duration}
            onChange={e => handleInputChange('duration', e.target.value)}
          />
          <Select
            mode="multiple"
            placeholder="Select badges"
            value={tripData.badge}
            onChange={handleBadgeChange}
            options={options}
            style={{ width: '100%' }}
          />


          <Select
            mode="multiple"
            placeholder="Select related trips"
            value={tripData.related_trips} // This will be an array of selected _id values
            onChange={handleRelatedTripsChange}
            options={relatedTripsOptions} // Pass the options array
            style={{ width: '100%' }}
          />
          <Input.TextArea
            placeholder="About This Activity"
            value={tripData.about}
            onChange={e => handleInputChange('about', e.target.value)}
            rows={4}
          />
          {tripData.do_this_because.map((item, index) => (
            <Input
              key={index}
              placeholder={`Do this because #${index + 1}`}
              value={item}
              onChange={e => handleArrayInputChange('do_this_because', index, e.target.value)}
              suffix={
                index > 0 && (
                  <MinusCircleOutlined
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => handleRemoveInput('do_this_because', index)}
                  />
                )
              }
            />
          ))}

          {tripData.do_this_because.length < maxInputs && (
            <Button type="dashed" onClick={() => handleAddInput('do_this_because')} block className="mt-2">
              Add Input
            </Button>
          )}
        </div>

        {/* Right Column */}
        <div className="flex flex-col gap-4">
          <Input.TextArea
            placeholder="Insights"
            value={tripData.insights}
            onChange={e => handleInputChange('insights', e.target.value)}
            rows={4}
          />
          {tripData.know_before_you_go.map((item, index) => (
            <Input
              key={index}
              placeholder={`Know before you go #${index + 1}`}
              value={item}
              onChange={e => handleArrayInputChange('know_before_you_go', index, e.target.value)}
              suffix={
                index > 0 && (
                  <MinusCircleOutlined
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => handleRemoveInput('know_before_you_go', index)}
                  />
                )
              }
            />
          ))}

          {tripData.know_before_you_go.length < maxInputs && (
            <Button type="dashed" onClick={() => handleAddInput('know_before_you_go')} block className=' mt-2'>
              Add Input
            </Button>
          )}

          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2 grid grid-cols-4 gap-4">
              <div>
                {tripData.present.map((item, index) => (
                  <Input
                    key={index}
                    placeholder={`Present #${index + 1}`}
                    value={item}
                    onChange={e => handleArrayInputChange('present', index, e.target.value)}
                    suffix={
                      index > 0 && (
                        <MinusCircleOutlined
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() => handleRemoveInput('present', index)}
                        />
                      )
                    }
                  />
                ))}

                {tripData.present && (
                  <Button type="dashed" onClick={() => handleAddInput('present')} block className="mt-2">
                    Add Input
                  </Button>
                )}

              </div>
              <div>
                <label>Suitable for</label>
                {['single', 'couple', 'children', 'group'].map((field) => (
                  <div key={field}>
                    <Switch
                      size='small'
                      onChange={(checked) => handleInputChange(`suitable_for_${field}`, checked)}
                    /> {field}
                  </div>
                ))}
              </div>
              <div>
                {tripData.include.map((item, index) => (
                  <Input
                    key={index}
                    placeholder={`Included #${index + 1}`}
                    value={item}
                    onChange={e => handleArrayInputChange('include', index, e.target.value)}
                    suffix={
                      index > 0 && (
                        <MinusCircleOutlined
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() => handleRemoveInput('include', index)}
                        />
                      )
                    }
                  />
                ))}

                {tripData.include && (
                  <Button type="dashed" onClick={() => handleAddInput('include')} block className="mt-2">
                    Add Input
                  </Button>
                )}

              </div>
              <div>
                <label>Languages</label>
                {['english', 'dutch', 'spanish', 'papiamentu'].map((field) => (
                  <div key={field}>
                    <Switch
                      size='small'
                      onChange={(checked) => handleInputChange(`languages_${field}`, checked)}
                    /> {field}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div>

            {tripData.what_to_bring.map((item, index) => (
              <Input
                key={index}
                placeholder={`What to bring #${index + 1}`}
                value={item}
                onChange={e => handleArrayInputChange('what_to_bring', index, e.target.value)}
                suffix={
                  index > 0 && (
                    <MinusCircleOutlined
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => handleRemoveInput('what_to_bring', index)}
                    />
                  )
                }
              />
            ))}

            {tripData.present && (
              <Button type="dashed" onClick={() => handleAddInput('what_to_bring')} block className="mt-2">
                Add Input
              </Button>
            )}
          </div>

          <div className="flex items-center gap-2">
            <Switch
              checked={tripData.pickup}
              onChange={checked => handleInputChange('pickup', checked)}
            />
            <span>Pickup</span>
          </div>
          {tripData.pickup && (
            <div className="grid grid-cols-2 gap-2 mt-2">
              <Input
                placeholder="Pickup Price"
                value={tripData.pickupPrice}
                onChange={e => handleInputChange('pickupPrice', e.target.value)}
                type="number"
                min={0}
              />
              <Input
                placeholder="Pickup Direction"
                value={tripData.pickupDirection}
                onChange={e => handleInputChange('pickupDirection', e.target.value)}
              />
            </div>
          )}

          <div className="flex items-center gap-2 mt-4">
            <Switch
              checked={tripData.api_integration.enabled}
              onChange={checked => handleNestedInputChange('api_integration', 'enabled', checked)}
            />
            <span>API Integration</span>
          </div>
          {tripData.api_integration.enabled && (
            <div className="grid grid-cols-1 gap-2 mt-2">
              <Input
                placeholder="API ID"
                value={tripData.api_integration.url}
                onChange={e => handleNestedInputChange('api_integration', 'url', e.target.value)}
              />
              <Input
                placeholder="API Key"
                value={tripData.api_integration.key}
                onChange={e => handleNestedInputChange('api_integration', 'key', e.target.value)}
              />
              <Input
                placeholder="API Secret"
                value={tripData.api_integration.secret}
                onChange={e => handleNestedInputChange('api_integration', 'secret', e.target.value)}
              />
              <div className="flex items-center gap-2">
                <Switch
                  checked={tripData.api_integration.fetch_price}
                  onChange={checked => handleNestedInputChange('api_integration', 'fetch_price', checked)}
                />
                <span>Fetch price from API</span>
              </div>
            </div>
          )}

          <div className="flex items-center gap-2 mt-4">
            <Switch
              checked={tripData.immediate_booking}
              onChange={checked => handleInputChange('immediate_booking', checked)}
            />
            <span>Immediate booking</span>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-2 mt-4">
        <Button onClick={onClose}>Cancel</Button>
        <Button type="primary" onClick={handleSave}>
          Save Trip
        </Button>
      </div>
    </Modal>
  );
};

export default AddTripModal;
