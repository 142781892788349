import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ImageTrip from '../Components/ImageTrip';
import { DatePicker, message, Select, Space } from 'antd';
import ImageCard from '../Components/ImageCard';
import MainFooter from '../Components/MainFooter';
import { Button, Modal } from 'antd';
import CompareModal from '../Components/CompareModal';
import { useEffect } from 'react';
import BlueHeader from '../Components/BlueHeader';
import CurrencyChange from '../Components/CurrencyChange';
import { axiosInstance } from '../API/authapi';
import { useCurrency } from '../Components/CurrencyContext';
import { CurrencyProvider } from '../Components/CurrencyContext';
import axios from 'axios';



const { RangePicker } = DatePicker;




function Homepage() {

  const [open, setOpen] = useState(false);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [trips, setTrips] = useState([]);
  const [specialDealsTrips, setSpecialDealsTrips] = useState([]);
  const [TrendingNowTrips, setTrendingNowTrips] = useState([]);
  const [ExperienceLocal, setExperienceLocal] = useState([]);
  const [beachesTrips, setbeachesTrips] = useState([]);
  const [thingsLocal, setThingsLocal] = useState([]);
  const [homeData, setHomeData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTrip, setSelectedTrip] = useState(null)

  const [categories, setCategories] = useState([]);
  const sortedcategories = categories.map((booking) => ({
    label: booking.category, // Display tripname
    value: booking.category, // Pass _id as the value
  }));


  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get('/api/category/all', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setCategories(response.data);
    } catch (error) {
      message.error('Failed to fetch categories');
    }
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const convertAmount = (amount) => {

    if (localStorage.getItem('selectedCurrency') === 'USD') {

      localStorage.setItem('currentFormat', 'USD')

      return `$ ${(amount * localStorage.getItem('eurToUsd')).toFixed(2)}`

    } else {

      localStorage.setItem('currentFormat', 'EUR')

      return `€ ${(amount * localStorage.getItem('usdToEur')).toFixed(2)}`

    }

  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post('/api/home/contact', formData);

      if (response.status === 200) {
        alert('Your request has been sent successfully!');
        setFormData({ name: '', email: '', description: '' });
      }
    } catch (error) {
      console.error('Error sending request:', error);
      alert('Failed to send request. Please try again.');
    }
  };

  useEffect(() => {
    let fetchedHomeData;
    axiosInstance.get('/api/home')
      .then(response => {
        fetchedHomeData = response.data[0];
        setHomeData(fetchedHomeData);
        return axiosInstance.get('/api/trips');
      })
      .then(response => {
        const allTrips = response.data;
        setTrips(allTrips);
        setFilteredTrips(allTrips)

        // Filter Special Deals trips
        if (fetchedHomeData.special_deals) {
          const specialDealsTrips = allTrips.filter(trip =>
            fetchedHomeData.special_deals.includes(trip._id)
          );
          setSpecialDealsTrips(specialDealsTrips);
        }

        // Filter Trending Now trips
        if (fetchedHomeData.trending_now) {
          const trendingTrips = allTrips.filter(trip =>
            fetchedHomeData.trending_now.includes(trip._id)
          );
          setTrendingNowTrips(trendingTrips);
        }

        // Filter Trending Now trips
        if (fetchedHomeData.experience_curacao) {
          const experienceLocal = allTrips.filter(trip =>
            fetchedHomeData.experience_curacao.includes(trip._id)
          );
          setExperienceLocal(experienceLocal);
        }
        if (fetchedHomeData.things_to_do) {
          const thingsLocal = allTrips.filter(trip =>
            fetchedHomeData.things_to_do.includes(trip._id)
          );
          setThingsLocal(thingsLocal);
        }

        if (fetchedHomeData.beaches) {
          const beaches = allTrips.filter(trip =>
            fetchedHomeData.beaches.includes(trip._id)
          );
          setbeachesTrips(beaches);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const navigate = useNavigate(); // Get the navigate function from react-router-dom


  const LikeButton = ({ id }) => {
    const [isFilled, setIsFilled] = useState(false);
    console.log(id);


    const handleClick = async (e) => {
      e.preventDefault();

      try {
        if (isFilled) {
          // Remove favorite trip 
          await axiosInstance.delete("/api/favorite/remove-favorite-trip", { data: { tripId: id } });
        } else {
          // Add favorite trip
          await axiosInstance.post("/api/favorite/add-favorite-trip", { tripId: id });
        }

        // Toggle the filled state after successful request
        setIsFilled(!isFilled);
      } catch (error) {
        // Check if the error is a 401 Unauthorized response
        if (error.response && error.response.status === 401) {
          // Clear local storage
          localStorage.clear();

          // Redirect to the home page
          navigate('/login');
        } else {
          console.error("An error occurred:", error);
          // Handle other errors or show a message to the user
        }
      }
    };
    return (
      <div
        className='bg-white w-8 h-8 rounded-full absolute top-1 right-1 flex justify-center items-center z-10'
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <svg
          width="18"
          height="15"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.50012 12.2822L1.64353 6.97733C-1.53939 3.7944 3.13951 -2.31681 7.50012 2.62733C11.8607 -2.31681 16.5184 3.81562 13.3567 6.97733L7.50012 12.2822Z"
            stroke="#F0B059"
            strokeWidth="1.06098"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={isFilled ? "orange" : "none"}
          />
        </svg>
      </div>
    );
  };
  // Likebuttonfunction


  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCompareClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  const handleTripSelection = (trip) => {
    setSelectedTrips((prevTrips) => {
      if (prevTrips.includes(trip)) {
        return prevTrips.filter((t) => t.id !== trip.id);
      } else if (prevTrips.length < 3) {
        return [...prevTrips, trip];
      }
      return prevTrips;
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const options = [
    {
      value: '1',
      label: 'Beaches',
    },
    {
      value: '2',
      label: 'Water activity',
    },
    {
      value: '3',
      label: 'Homestay',
    },
    {
      value: '4',
      label: 'Explore food',
    },
    {
      value: '5',
      label: 'Local experience',
    },
    // {
    //   value: '6',
    //   label: 'Cancelled',
    // }
  ];

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    if (value) {
      // Filter trips by the selected category
      const filtered = trips.filter(trip => trip.category === value);
      setFilteredTrips(filtered);
    } else {
      // If no category is selected, show all trips
      setFilteredTrips(trips);
    }
    setSelectedTrip(null); // Reset selected trip when category changes 
  };

  const [currency, setCurrency] = useState(localStorage.getItem('currency') || 'USD');
  const [exchangeRates, setExchangeRates] = useState({});

  // Function to fetch exchange rates
  const fetchExchangeRates = async () => {
    try {
      const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
      setExchangeRates(response.data.rates);
      console.log('Exchange rates updated:', response.data.rates);
    } catch (error) {
      console.error('Failed to fetch exchange rates:', error);
    }
  };

  // Price conversion function
  const convertPrice = (priceUSD) => {
    if (currency === 'USD') return priceUSD.toFixed(2);
    if (!exchangeRates[currency]) return priceUSD;
    return (priceUSD * exchangeRates[currency]).toFixed(2);
  };

  const [price, setPrice] = useState(convertPrice(0));

  // Effect to fetch exchange rates on mount
  useEffect(() => {
    fetchExchangeRates();
  }, []);

  // Effect to listen for local storage changes
  useEffect(() => {
    const handleStorageChange = () => {
      const newCurrency = localStorage.getItem('currency');
      if (newCurrency) {
        setCurrency(newCurrency);
        setPrice(convertPrice(trips.price)); // Convert the price with the new currency
      }
    };

    // Add event listener for local storage changes
    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [trips.price]); // Dependency on trip price


  const handleTripChange = (value) => {
    setSelectedTrip(value);
  };

  const handleSearchClick = () => {
    const queryParams = new URLSearchParams();
    if (selectedCategory) {
      queryParams.append('category', selectedCategory);
      navigate(`/activities?${queryParams.toString()}`);
    }
    if (selectedTrip) {
      navigate(`/trip/${selectedTrip}`);
    }

  };


  const BASE_URL = "https://tripcurascobackend-production.up.railway.app/"; // Replace with your actual base URL

  const heroImageUrl = `${BASE_URL}${homeData.photos}` ;


  return (
    <main className=' '>
      <BlueHeader />

      {/* hero section */}
      <section className="overflow-hidden relative md:-mt-16 -z-0" style={{ height: '750px' }}>
        {/* ... (hero image and overlay remain the same) */}
        <img src={heroImageUrl} alt="Hero" className="w-full h-full object-cover absolute inset-0" />
        <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent opacity-75" style={{ height: '100%' }}></div>

        <div className="w-full md:w-2/3 absolute inset-0 flex items-left flex-col justify-center p-8 gap-10">
          <h1 className="text-4xl text-center text-white md:text-7xl md:text-left font-extrabold">
            {homeData.title || 'Plan Your Dream Curaçao Getaway'}
          </h1>
          <div className="md:pr-1 md:pl-3 md:p-0 md:w-9/12 bg-slate-50 rounded-2xl border-2 border-solid border-orange-300 flex flex-col md:flex-row justify-between items-center p-4">
            <Select
              variant='borderless'

              className='w-full md:w-5/12'
              showSearch
              placeholder="Select a category"
              optionFilterProp="children"
              onChange={handleCategoryChange}
              value={selectedCategory}
            >
              {categories.map(category => (
                <Select.Option key={category._id} value={category.category}>
                  {category.category}
                </Select.Option>
              ))}
            </Select>
            <Select
              className='w-full md:w-5/12'
              variant='borderless'
              showSearch
              placeholder="Select a trip"
              optionFilterProp="children"
              onChange={handleTripChange}
              value={selectedTrip}
            >
              {filteredTrips.map(trip => (
                <Select.Option key={trip._id} value={trip._id}>
                  {trip.tripname}
                </Select.Option>
              ))}
            </Select>
            <button
              className="w-full gap-6 h-12 mybutton rounded-xl md:w-2/12 md:h-12 flex justify-center items-center " style={{ 'marginRight': '-4px' }}
              onClick={handleSearchClick}
            >
              <p className="md:hidden text-white">Search activity</p>
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6122 1.78183L1.62524 14.7688" stroke="#FEFEFE" strokeWidth="1.81243" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M15.1218 13.7082V3.03759C15.1229 2.80551 15.0779 2.57552 14.9896 2.36091C14.9012 2.1463 14.7712 1.95134 14.607 1.78728C14.4429 1.62322 14.2478 1.49333 14.0332 1.40512C13.8185 1.3169 13.5885 1.2721 13.3564 1.27332H2.68579" stroke="#FEFEFE" strokeWidth="1.81243" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
          <a href="/activities" className="w-full mybutton md:w-1/3 text-slate-50 border border-white rounded-xl p-3 flex justify-center items-center text-lg">
            Explore all activities
          </a>
        </div>
      </section>

      {/* end of hero section */}

      {/* special deals */}

      <section className="p-5 md:p-8">
        <div className='flex justify-between'>
          <div>
            <h1 className="text-2xl text-theblue-400 md:text-3xl font-bold">Special deals</h1>
          </div>
          {specialDealsTrips.length > 6 && (
            <a href='/activities' className='text-sm text-nowrap bg-theorange-400 h-fit px-4 py-2 opacity-75 hover:opacity-100 transition-all text-white rounded-md cursor-pointer md:text-base'>
              See more
            </a>
          )}
        </div>
        <div className="flex overflow-scroll gap-4 mt-3 pb-7 relative scroll-smooth">
          {specialDealsTrips.map((trip) => (
            <div key={trip._id} className="flex-1 md:min-w-[320px]">
              <div className="bg-white border-2 opacity-95 border-white rounded-3xl p-2 hover:border-2 hover:border-theorange-400 transition-all relative shadow-sm image-container w-[280px] md:w-[320px] min-h-[380px]">
                <div className='relative group'>
                  <Link to={`/trip/${trip._id}`} className="block">
                    {trip.badge && trip.badge[0] && (
                      <img
                        src={`../../assets/svg/allbadges/${trip.badge[0]}.svg`}
                        alt=""
                        className="absolute w-36 top-1 left-1"
                      />
                    )}
                    <div className='shiny-hover'></div>
                    <img className='w-full h-44 object-cover rounded-xl' src={`https://tripcurascobackend-production.up.railway.app/${trip.mainphoto}`} alt={trip.tripname} />
                    <LikeButton id={trip._id} />
                  </Link>
                </div>
                <div className='flex justify-between mt-2'>
                  <h2 className="text-md font-medium w-3/4 leading-tight m text-gray-700">{trip.tripname}</h2>
                  {trip.averageRating !== null && (
                    <div className="flex items-center gap-2">
                      <p className="text-gray-500">{trip.averageRating}</p>
                      <img src="../../assets/svg/star.svg" alt="Star" />
                    </div>
                  )}

                </div>
                <p className="text-theorange-400 font-normal text-xs">{trip.location}</p>
                <div className='flex justify-between absolute bottom-5 w-11/12'>
                  <div className='flex flex-col justify-center gap-1'>
                    {trip.duration !== '0' && (
                      <div className='flex items-center gap-2 text-gray-500 font-normal text-xs text-left'>
                        <img src="../../assets/svg/3days.svg" alt="" />
                        {trip.duration}-hours
                      </div>
                    )}

                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/bprice.svg" alt="Best price" />
                      E-ticket available
                    </div>
                    <div className='flex justify-center items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/24hr.svg" alt="24 hours cancellation" />
                      Free 24 hours cancellation
                    </div>
                  </div>
                  <div className='flex flex-col text-right absolute bottom-0 right-0 gap-3'>
                    <button
                      className={`p-1 pl-3 pr-3 ${selectedTrips.includes(trip) ? 'bg-theorange-400' : 'bg-theblue-400'} text-slate-50 font-normal rounded-md text-xs border-none`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleTripSelection(trip);
                      }}
                    >
                      {selectedTrips.includes(trip) ? 'Remove' : 'Compare'}
                    </button>
                    <p className='text-xl font-semibold text-theblue-400 leading-none'>{convertAmount(trip.price)}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {selectedTrips.length > 0 && (
            <button
              className="fixed top-5 right-4 md:right-1/2 p-3 bg-theblue-400 text-slate-50 font-normal rounded-lg text-base border-none z-50 shadow-md"
              onClick={handleCompareClick}
            >
              Compare Selected Trips
            </button>
          )}
          {selectedTrips.length > 0 && (
            <Modal
              centered
              open={open}
              onOk={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              width={1600}
              className="overflow-hidden"
              bodyStyle={{ overflowY: 'auto' }}
            >
              <CompareModal trips={selectedTrips} />
            </Modal>
          )}
        </div>
      </section>

      {/* special deals */}

      {/* trending now */}
      <section className="p-5 md:p-8 ">
        <div className='flex justify-between'>
          <div>
            <h1 className="text-2xl text-theblue-400 md:text-3xl font-bold">Trending Now</h1>
          </div>
          {TrendingNowTrips.length > 6 && (
            <a href='/activities' className='text-sm text-nowrap bg-theorange-400 h-fit px-4 py-2 opacity-75 hover:opacity-100 transition-all text-white rounded-md cursor-pointer md:text-base'>
              See more
            </a>
          )}
        </div>

        <div className="flex overflow-scroll gap-4 mt-3 pb-7 relative scroll-smooth">
          {/* arrow mark */}
          {/* <div className=' w-12 h-12 bg-white rounded-full absolute z-50 flex justify-center items-center bg-opacity-55 backdrop-blur-md top-32 -right-2 '>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 6l6 6l-6 6" />
      </svg>
    </div> */}
          {/* arrow mark */}
          {TrendingNowTrips.map((trip) => (
            <div key={trip._id} className="flex-1 md:min-w-[320px]">
              <div className="bg-white bg-opacity-95 border-2 border-white rounded-3xl p-2 hover:border-2 hover:border-theblue-400 transition-all relative shadow-sm image-container w-[280px] md:w-[320px] min-h-[380px]">
                <div className='relative group'>
                  <Link to={`/trip/${trip._id}`} className="block">
                    {trip.badge && trip.badge[0] && (
                      <img
                        src={`../../assets/svg/allbadges/${trip.badge[0]}.svg`}
                        alt=""
                        className="absolute w-36 top-1 left-1"
                      />
                    )}
                    {/* <div className='shiny-hover'></div> */}
                    <img className='w-full h-44 object-cover rounded-xl' src={`https://tripcurascobackend-production.up.railway.app/${trip.mainphoto}`} />
                    <LikeButton id={trip._id} />
                  </Link>
                </div>
                <div className='flex justify-between mt-2'>
                  <h2 className="text-md font-medium w-3/4 leading-tight m text-gray-700">{trip.tripname}</h2>
                  {trip.averageRating !== null && (
                    <div className="flex items-center gap-2">
                      <p className="text-gray-500">{trip.averageRating}</p>
                      <img src="../../assets/svg/star.svg" alt="Star" />
                    </div>
                  )}

                </div>
                <p className="text-theorange-400 font-normal text-xs">{trip.location}</p>
                <div className='flex justify-between absolute bottom-5 w-11/12'>
                  <div className='flex flex-col justify-center gap-1'>
                    {trip.duration !== '0' && (
                      <div className='flex items-center gap-2 text-gray-500 font-normal text-xs text-left'>
                        <img src="../../assets/svg/3days.svg" alt="" />
                        {trip.duration}-hours
                      </div>
                    )}

                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/bprice.svg" alt="" />
                      Best price
                    </div>
                    <div className='flex justify-center items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/24hr.svg" alt="" />
                      free 24 hours cancellation
                    </div>
                  </div>
                  <div className='flex flex-col text-right absolute bottom-0 right-0 gap-3'>
                    <button
                      className={`p-1 pl-3 pr-3 ${selectedTrips.includes(trip) ? 'bg-theorange-400' : 'bg-theblue-400'} text-slate-50 font-normal rounded-md text-xs border-none`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleTripSelection(trip);
                      }}
                    >
                      {selectedTrips.includes(trip) ? 'Remove' : 'Compare'}
                    </button>
                    <p className='text-xl font-semibold text-theblue-400 leading-none'>{convertAmount(trip.price)}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {selectedTrips.length > 0 && (
            <button
              className="fixed top-5 right-4 md:right-1/2 p-3 bg-theblue-400 text-slate-50 font-normal rounded-lg text-base border-none z-50 shadow-md "
              onClick={handleCompareClick}
            >
              Compare Selected Trips
            </button>
          )}
          {selectedTrips.length > 0 && (
            <Modal
              centered
              open={open}
              onOk={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              width={1600}
              className="overflow-hidden"
              bodyStyle={{ overflowY: 'auto' }}
            >
              <CompareModal trips={selectedTrips} />
            </Modal>
          )}
        </div>
      </section>

      {/* trending now */}

      {/* why curacao */}
      <section className='p-8'>
        <div className=' flex gap-5 md:py-7 flex-col-reverse  md:flex-row justify-around items-center '>
          {/* <div className=' flex justify-center items-center flex-col bg-white bg-opacity-85 p-3 gap-5 pb-6 border-2 border-orange-300 shadow-lg'>
            <img src="../../assets/why-curacao.jpg" alt="" className=' w-80 h-80 object-cover' />
            <p className='logo text-3xl text-theblue-400 opacity-25 cursor-default text-center'>TheTrip <br />Curaçao</p>
          </div> */}
          <ImageCard image='../../assets/why-curacao.jpg' />

          <div className=' w-full md:w-1/2 flex flex-col  gap-10 '>
            <h1 className=' text-2xl font-bold text-theblue-400'>Why book with TheTripCuraçao</h1>
            <p className=' font-extralight text-gray-500'>We are proud to be a company owned by locals, deeply rooted in the vibrant culture and community of Curacao. When you book with us, you support local businesses and contribute to the island's economy, helping to preserve our unique heritage. Our cutting- edge technology allows us to offer unbeatable prices, ensuring great value without compromising quality. We strive to offer the largest assortment of tours in Curacao. our diverse options ensure you'll find the perfect tour to match your interests and preferences.</p>
          </div>
        </div>
      </section >
      {/* why curacao */}

      {/* Experience Curacao like a local */}
      <section className="p-5 md:p-8 ">
        <div className='flex justify-between'>
          <div>
            <h1 className="text-2xl text-theblue-400 md:text-3xl font-bold">Experience Curacao like a local</h1>
          </div>
          {ExperienceLocal.length > 6 && (
            <a href='/activities' className='text-sm text-nowrap bg-theorange-400 h-fit px-4 py-2 opacity-75 hover:opacity-100 transition-all text-white rounded-md cursor-pointer md:text-base'>
              See more
            </a>
          )}
        </div>

        <div className="flex overflow-scroll gap-4 mt-3 pb-7 relative scroll-smooth ">
          {/* arrow mark */}
          {/* <div className=' w-12 h-12 bg-white rounded-full absolute z-50 flex justify-center items-center bg-opacity-55 backdrop-blur-md top-32 -right-2 '>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 6l6 6l-6 6" />
            </svg>
          </div> */}
          {/* arrow mark */}
          {ExperienceLocal.map((trip) => (
            <div key={trip._id} className="flex-1 md:min-w-[320px]">
              <div className="bg-white bg-opacity-95 border-2 border-white rounded-3xl p-2 hover:border-2 hover:border-theblue-400 transition-all relative shadow-sm image-container w-[280px] md:w-[320px] min-h-[380px]">
                <div className='relative group'>
                  <Link to={`/trip/${trip._id}`} className="block">
                    {trip.badge && trip.badge[0] && (
                      <img
                        src={`../../assets/svg/allbadges/${trip.badge[0]}.svg`}
                        alt=""
                        className="absolute w-36 top-1 left-1"
                      />
                    )}
                    {/* <div className='shiny-hover'></div> */}
                    <img className='w-full h-44 object-cover rounded-xl' src={`https://tripcurascobackend-production.up.railway.app/${trip.mainphoto}`} />
                    <LikeButton id={trip._id} />
                  </Link>
                </div>
                <div className='flex justify-between mt-2'>
                  <h2 className="text-md font-medium w-3/4 leading-tight m text-gray-700">{trip.tripname}</h2>
                  {trip.averageRating !== null && (
                    <div className="flex items-center gap-2">
                      <p className="text-gray-500">{trip.averageRating}</p>
                      <img src="../../assets/svg/star.svg" alt="Star" />
                    </div>
                  )}

                </div>
                <p className="text-theorange-400 font-normal text-xs">{trip.location}</p>
                <div className='flex justify-between absolute bottom-5 w-11/12'>
                  <div className='flex flex-col justify-center gap-1'>
                    {trip.duration !== '0' && (
                      <div className='flex items-center gap-2 text-gray-500 font-normal text-xs text-left'>
                        <img src="../../assets/svg/3days.svg" alt="" />
                        {trip.duration}-hours
                      </div>
                    )}

                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/bprice.svg" alt="" />
                      E-ticket Available
                    </div>
                    <div className='flex justify-center items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/24hr.svg" alt="" />
                      free 24 hours cancellation
                    </div>
                  </div>
                  <div className='flex flex-col text-right absolute bottom-0 right-0 gap-3'>
                    <button
                      className={`p-1 pl-3 pr-3 ${selectedTrips.includes(trip) ? 'bg-theorange-400' : 'bg-theblue-400'} text-slate-50 font-normal rounded-md text-xs border-none`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleTripSelection(trip);
                      }}
                    >
                      {selectedTrips.includes(trip) ? 'Remove' : 'Compare'}
                    </button>
                    <p className='text-xl font-semibold text-theblue-400 leading-none'>{convertAmount(trip.price)}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {selectedTrips.length > 0 && (
            <button
              className="fixed top-5 right-4 md:right-1/2 p-3 bg-theblue-400 text-slate-50 font-normal rounded-lg text-base border-none z-50 shadow-md "
              onClick={handleCompareClick}
            >
              Compare Selected Trips
            </button>
          )}
          {selectedTrips.length > 0 && (
            <Modal
              centered
              open={open}
              onOk={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              width={1600}
              className="overflow-hidden"
              bodyStyle={{ overflowY: 'auto' }}
            >
              <CompareModal trips={selectedTrips} />
            </Modal>
          )}




        </div>
      </section>
      {/* Experience Curacao like a local  */}


      {/* features box */}
      <section className='p-8 hidden md:block'>
        <div className=' w-full h-32 bg-theblue-400 rounded-3xl flex justify-normal items-center'>
          <div className=' flex justify-center items-center w-1/4 gap-4' >
            <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3125 40.125L29.4688 16.2188L40.625 40.125M21.5 33.75H37.4375M2.375 7.44462C6.6046 6.9183 10.8628 6.655 15.125 6.65625M15.125 6.65625C17.505 6.65625 19.8701 6.737 22.2097 6.8985M15.125 6.65625V1.875M22.2097 6.8985C19.749 18.1482 12.3413 27.545 2.375 32.6918M22.2097 6.8985C24.1029 7.0274 25.9921 7.20951 27.875 7.44462M18.1234 25.4965C14.6383 21.9533 11.8762 17.7657 9.991 13.1673" stroke="white" stroke-opacity="0.83" stroke-width="3.1875" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p className=' text-gray-100 text-lg'>Multi-language <br />tour guides</p>
          </div>
          <span className=' h-3/4 rounded-full bg-gray-400 ' style={{ width: '1px' }}></span>
          <div className=' flex justify-center items-center w-1/4 gap-4' >
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24">
              <path fill="white" fillOpacity="0.83" d="M15.25 2h-6.5A6.76 6.76 0 0 0 2 8.75v6.5A6.76 6.76 0 0 0 8.75 22h6.5A6.76 6.76 0 0 0 22 15.25v-6.5A6.76 6.76 0 0 0 15.25 2m1.65 11a10.12 10.12 0 0 1-4.58 4.29a.77.77 0 0 1-.64 0A10.12 10.12 0 0 1 7.1 13a4.16 4.16 0 0 1 .62-4.69a2.8 2.8 0 0 1 2.49-.61a3.25 3.25 0 0 1 1.79 1a3.28 3.28 0 0 1 1.79-1a2.83 2.83 0 0 1 2.5.61a4.18 4.18 0 0 1 .61 4.65z" />
            </svg>
            <p className=' text-gray-100 text-lg'>trusted partner  <br />of tour operators</p>
          </div>
          <span className=' h-3/4 rounded-full bg-gray-400 ' style={{ width: '1px' }}></span>
          <div className=' flex justify-center items-center w-1/4 gap-4' >
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24">
              <path fill="white" fillOpacity={0.83} d="m19.687 14.093l.184-1.705c.097-.91.162-1.51.111-1.888H20a1.5 1.5 0 1 0-1.136-.52c-.326.201-.752.626-1.393 1.265c-.495.493-.742.739-1.018.777a.833.833 0 0 1-.45-.063c-.254-.112-.424-.416-.763-1.025l-1.79-3.209c-.209-.375-.384-.69-.542-.942a2 2 0 1 0-1.816 0c-.158.253-.333.567-.543.942L8.76 10.934c-.34.609-.51.913-.764 1.025a.833.833 0 0 1-.45.063c-.275-.038-.522-.284-1.017-.777c-.641-.639-1.067-1.064-1.393-1.265A1.5 1.5 0 1 0 4 10.5h.018c-.051.378.014.979.111 1.888l.184 1.705c.102.946.186 1.847.29 2.657h14.794c.104-.81.188-1.71.29-2.657ZM10.912 21h2.176c2.836 0 4.254 0 5.2-.847c.413-.37.674-1.036.863-1.903H4.849c.189.867.45 1.534.863 1.903c.946.847 2.364.847 5.2.847Z" />
            </svg>
            <p className=' text-gray-100 text-lg'>owned by born<br />and raised locals</p>
          </div>
          <span className=' h-3/4 rounded-full bg-gray-400 ' style={{ width: '1px' }}></span>
          <div className=' flex justify-center items-center w-1/4 gap-4' >
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
              <path fill="white" fillOpacity={0.83} fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5Zm9-3a.75.75 0 0 1 .728.568l.258 1.036a2.63 2.63 0 0 0 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258a2.63 2.63 0 0 0-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395a1.5 1.5 0 0 0-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395a1.5 1.5 0 0 0 .948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
            </svg>
            <p className=' text-gray-100 text-lg'>Easy booking<br />system</p>
          </div>
        </div>
      </section>
      {/* features box */}

      {/* Beaches */}
      <section className="p-5 md:p-8 ">
        <div className='flex justify-between'>
          <div>
            <h1 className="text-2xl text-theblue-400 md:text-3xl font-bold">Beaches</h1>
          </div>
          {beachesTrips.length > 6 && (
            <a href='/activities' className='text-sm text-nowrap bg-theorange-400 h-fit px-4 py-2 opacity-75 hover:opacity-100 transition-all text-white rounded-md cursor-pointer md:text-base'>
              See more
            </a>
          )}
        </div>


        <div className="flex overflow-scroll gap-4 mt-3 pb-7 relative scroll-smooth ">
          {/* arrow mark */}
          {/* <div className=' w-12 h-12 bg-white rounded-full absolute z-50 flex justify-center items-center bg-opacity-55 backdrop-blur-md top-32 -right-2 '>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 6l6 6l-6 6" />
            </svg>
          </div> */}
          {/* arrow mark */}
          {beachesTrips.map((trip) => (
            <div key={trip.id} className="flex-1 md:min-w-[320px]">
              <div className="bg-white bg-opacity-95 border-2 border-white rounded-3xl p-2 hover:border-2 hover:border-theblue-400 transition-all relative shadow-sm image-container w-[280px] md:w-[320px] min-h-[380px]">
                <div className='relative group'>
                  <Link to={`/trip/${trip._id}`} className="block">
                    {trip.badge && trip.badge[0] && (
                      <img
                        src={`../../assets/svg/allbadges/${trip.badge[0]}.svg`}
                        alt=""
                        className="absolute w-36 top-1 left-1"
                      />
                    )}
                    {/* <div className='shiny-hover'></div> */}
                    <img className='w-full h-44 object-cover rounded-xl' src={BASE_URL + trip.mainphoto} />
                    <LikeButton id={trip._id} />
                  </Link>
                </div>
                <div className='flex justify-between mt-2'>
                  <h2 className="text-md font-medium w-3/4 leading-tight m text-gray-700">{trip.tripname}</h2>
                  {trip.averageRating !== null && (
                    <div className="flex items-center gap-2">
                      <p className="text-gray-500">{trip.averageRating}</p>
                      <img src="../../assets/svg/star.svg" alt="Star" />
                    </div>
                  )}

                </div>
                <p className="text-theorange-400 font-normal text-xs">{trip.location}</p>
                <div className='flex justify-between absolute bottom-5 w-11/12'>
                  <div className='flex flex-col justify-center gap-1'>
                    {trip.duration !== '0' && (
                      <div className='flex items-center gap-2 text-gray-500 font-normal text-xs text-left'>
                        <img src="../../assets/svg/3days.svg" alt="" />
                        {trip.duration}-hours
                      </div>
                    )}

                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/bprice.svg" alt="" />
                      Best price
                    </div>
                    <div className='flex justify-center items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/24hr.svg" alt="" />
                      free 24 hours cancellation
                    </div>
                  </div>
                  <div className='flex flex-col text-right absolute bottom-0 right-0 gap-3'>
                    <button
                      className={`p-1 pl-3 pr-3 ${selectedTrips.includes(trip) ? 'bg-theorange-400' : 'bg-theblue-400'} text-slate-50 font-normal rounded-md text-xs border-none`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleTripSelection(trip);
                      }}
                    >
                      {selectedTrips.includes(trip) ? 'Remove' : 'Compare'}
                    </button>
                    <p className='text-xl font-semibold text-theblue-400 leading-none'>{convertAmount(trip.price)}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {selectedTrips.length > 0 && (
            <button
              className="fixed top-5 right-4 md:right-1/2 p-3 bg-theblue-400 text-slate-50 font-normal rounded-lg text-base border-none z-50 shadow-md "
              onClick={handleCompareClick}
            >
              Compare Selected Trips
            </button>
          )}
          {selectedTrips.length > 0 && (
            <Modal
              centered
              open={open}
              onOk={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              width={1600}
              className="overflow-hidden"
              bodyStyle={{ overflowY: 'auto' }}
            >
              <CompareModal trips={selectedTrips} />
            </Modal>
          )}




        </div>
      </section>
      {/* Beaches  */}

      {/* things to do */}
      <section className="p-5 md:p-8 ">
        <div className='flex justify-between'>
          <div>
            <h1 className="text-2xl text-theblue-400 md:text-3xl font-bold">Things to do</h1>
          </div>
          {thingsLocal.length > 6 && (
            <a href='/activities' className='text-sm text-nowrap bg-theorange-400 h-fit px-4 py-2 opacity-75 hover:opacity-100 transition-all text-white rounded-md cursor-pointer md:text-base'>
              See more
            </a>
          )}
        </div>
        <div className="flex overflow-scroll gap-4 mt-3 pb-7 relative scroll-smooth ">
          {/* arrow mark */}
          {/* <div className=' w-12 h-12 bg-white rounded-full absolute z-50 flex justify-center items-center bg-opacity-55 backdrop-blur-md top-32 -right-2 '>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 6l6 6l-6 6" />
            </svg>
          </div> */}
          {/* arrow mark */}
          {thingsLocal.map((trip) => (
            <div key={trip._id} className="flex-1 md:min-w-[320px]">
              <div className="bg-white bg-opacity-95 border-2 border-white rounded-3xl p-2 hover:border-2 hover:border-theblue-400 transition-all relative shadow-sm image-container w-[280px] md:w-[320px] min-h-[380px]">
                <div className='relative group'>
                  <Link to={`/trip/${trip._id}`} className="block">
                    {trip.badge && trip.badge[0] && (
                      <img
                        src={`../../assets/svg/allbadges/${trip.badge[0]}.svg`}
                        alt=""
                        className="absolute w-36 top-1 left-1"
                      />
                    )}
                    {/* <div className='shiny-hover'></div> */}
                    <img className='w-full h-44 object-cover rounded-xl' src={`https://tripcurascobackend-production.up.railway.app/${trip.mainphoto}`} />
                    <LikeButton id={trip._id} />
                  </Link>
                </div>
                <div className='flex justify-between mt-2'>
                  <h2 className="text-md font-medium w-3/4 leading-tight m text-gray-700">{trip.tripname}</h2>
                  {trip.averageRating !== null && (
                    <div className="flex items-center gap-2">
                      <p className="text-gray-500">{trip.averageRating}</p>
                      <img src="../../assets/svg/star.svg" alt="Star" />
                    </div>
                  )}

                </div>
                <p className="text-theorange-400 font-normal text-xs">{trip.location}</p>
                <div className='flex justify-between absolute bottom-5 w-11/12'>
                  <div className='flex flex-col justify-center gap-1'>
                    {trip.duration !== '0' && (
                      <div className='flex items-center gap-2 text-gray-500 font-normal text-xs text-left'>
                        <img src="../../assets/svg/3days.svg" alt="" />
                        {trip.duration}-hours
                      </div>
                    )}

                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/bprice.svg" alt="" />
                      Best price
                    </div>
                    <div className='flex justify-center items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/24hr.svg" alt="" />
                      free 24 hours cancellation
                    </div>
                  </div>
                  <div className='flex flex-col text-right absolute bottom-0 right-0 gap-3'>
                    <button
                      className={`p-1 pl-3 pr-3 ${selectedTrips.includes(trip) ? 'bg-theorange-400' : 'bg-theblue-400'} text-slate-50 font-normal rounded-md text-xs border-none`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleTripSelection(trip);
                      }}
                    >
                      {selectedTrips.includes(trip) ? 'Remove' : 'Compare'}
                    </button>
                    <p className='text-xl font-semibold text-theblue-400 leading-none'>{convertAmount(trip.price)}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {selectedTrips.length > 0 && (
            <button
              className="fixed top-5 right-4 md:right-1/2 p-3 bg-theblue-400 text-slate-50 font-normal rounded-lg text-base border-none z-50 shadow-md "
              onClick={handleCompareClick}
            >
              Compare Selected Trips
            </button>
          )}
          {selectedTrips.length > 0 && (
            <Modal
              centered
              open={open}
              onOk={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              width={1600}
              className="overflow-hidden"
              bodyStyle={{ overflowY: 'auto' }}
            >
              <CompareModal trips={selectedTrips} />
            </Modal>
          )}




        </div>
      </section>
      {/* THings to do  */}

      {/* special-request */}
      <section className='p-8'>
        <div className=' flex gap-5 md:py-7 flex-col  md:flex-row justify-around items-center '>
          {/* <div className=' flex justify-center items-center flex-col bg-white bg-opacity-85 p-3 gap-5 pb-6 border-2 border-orange-300 shadow-lg'>
            <img src="../../assets/special-request.jpg" alt="" className=' w-80 h-80 object-cover' />
            <p className='logo text-3xl text-theblue-400 opacity-25 cursor-default text-center'>TheTrip <br />Curaçao</p>
          </div> */}
          <ImageCard image='../../assets/special-request.jpg' />
          <div className=' w-full md:w-1/2 flex flex-col  gap-10 '>
            <h1 className=' text-2xl font-bold text-theblue-400'>Personalize Your Curaçao Adventure</h1>
            <p className=' font-extralight text-gray-500'>Our special request section allows you to create a personalized and unique experience for your Curaçao trip. Whether it's a dream wedding, a private party, or any other special occasion, we cater to your specific needs and preferences. Simply fill out our form with details about your event, and our team will design a custom package just for you. Let us bring your vision to life and create unforgettable memories in the stunning backdrop of Curaçao.</p>
            <a href="/contact" className=' mybutton w-fit text-slate-50 border rounded-xl p-3 pl-6 pr-6 flex justify-center items-center text-lg'>Contact Us</a>
          </div>
        </div>
      </section >
      {/* special-request */}

      {/* contact-us */}
      <section className='p-8'>
        <div className=' flex gap-5 md:hpy-7 flex-col  md:flex-row-reverse justify-around items-center'>
          {/* <div className=' flex justify-center items-center flex-col bg-white bg-opacity-85 p-3 gap-5 pb-6 border-2 border-orange-300 shadow-lg'>
            <img src="../../assets/special-request.jpg" alt="" className=' w-80 h-80 object-cover' />
            <p className='logo text-3xl text-theblue-400 opacity-25 cursor-default text-center'>TheTrip <br />Curaçao</p>
          </div> */}
          <ImageCard image='../../assets/contact.jpg' />
          <div className=' w-full md:w-1/2 flex flex-col  gap-10 '>
            <h1 className=' text-2xl font-bold text-theblue-400'>Connect and Start Your Curaçao Adventure</h1>
            <p className=' font-extralight text-gray-500'>We’d love to hear from you! Whether you have questions about our tours, need help planning your trip, or want to share feedback, our team is here to assist you.</p>
            <form onSubmit={handleSubmit} className=' flex flex-col gap-4'>
              <div className=' flex gap-4'>
                <input type="text" name="name" className=' w-1/2 border border-theorange-400 rounded-xl bg-white bg-opacity-50 p-3 text-theblue-400 placeholder:font-light' placeholder='Name' value={formData.name}
                  onChange={handleChange} required />
                <input type="text" name="email" className=' w-1/2 border border-theorange-400 rounded-xl bg-white bg-opacity-50 p-3 text-theblue-400 placeholder:font-light' placeholder='mail Id' typeof='e-mail' value={formData.email}
                  onChange={handleChange} required />
              </div>
              <div className='border border-theorange-400 rounded-xl bg-white bg-opacity-50 h-14 text-theblue-400 flex relative items-center'>
                <input name="description" type="text" className='bg-transparent border-none h-full rounded-xl w-full p-3 placeholder:font-light' placeholder='enter your desription' value={formData.description}
                  onChange={handleChange} required />
                <button type="submit" className='mybutton rounded-xl w-12 h-12 flex justify-center items-center absolute right-1' >
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.6122 1.78183L1.62524 14.7688" stroke="#FEFEFE" stroke-width="1.81243" stroke-miterlimit="10" stroke-linecap="round" />
                    <path d="M15.1218 13.7082V3.03759C15.1229 2.80551 15.0779 2.57552 14.9896 2.36091C14.9012 2.1463 14.7712 1.95134 14.607 1.78728C14.4429 1.62322 14.2478 1.49333 14.0332 1.40512C13.8185 1.3169 13.5885 1.2721 13.3564 1.27332H2.68579" stroke="#FEFEFE" stroke-width="1.81243" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
              </div>

            </form>
            {/* <a href="" className=' mybutton w-fit text-slate-50 border rounded-xl p-3 pl-6 pr-6 flex justify-center items-center text-lg'>Contact Us</a> */}
          </div>
        </div>
      </section >
      {/* contact-us */}



      {homeData && <MainFooter home={homeData} />}
    </main>
  );
}


export default Homepage;
