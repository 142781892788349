import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Input, Button, Select, Modal, Checkbox, Form, notification, Switch } from 'antd';
import SecondaryHeader from '../Components/SecondaryHeader';
import { axiosInstance } from '../API/authapi';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const { Option } = Select;

const Checkout = () => {
  const location = useLocation();
  const { finalDetails } = location.state || {};

  console.log('finalDetails:', finalDetails);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState(''); 
  const [phone, setPhone] = useState('');
  const [specialRequests, setSpecialRequests] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentAddress, setPaymentAddress] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [needPickup, setNeedPickup] = useState(false);
  const [pickupCity, setPickupCity] = useState('');
  const [pickupLandmark, setPickupLandmark] = useState('');
  const [pickupAddress, setPickupAddress] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [subscriptionEmail, setSubscriptionEmail] = useState('');
  const [totalPrice, setTotalPrice] = useState(finalDetails ? finalDetails.updatedTotalPrice : 0);



  const handleConfirmBooking = () => { 
    setIsModalVisible(true);
  };



  useEffect(() => {
    if (finalDetails) {
      let newTotalPrice = finalDetails.updatedTotalPrice;
      if (needPickup && finalDetails.pickup && finalDetails.pickupPrice) {
        newTotalPrice += parseFloat(finalDetails.pickupPrice);
      }
      setTotalPrice(newTotalPrice);
    }
  }, [needPickup, finalDetails]);

  const handleModalOk = async () => {
    // Validate required fields
    if (!fullName || !email || !phone || !paymentMethod || !paymentAddress) {
      notification.error({
        message: 'Validation Error',
        description: 'Please fill in all required fields.',
      });
      return;
    }
  
    // Validate pickup details if pickup is needed
    if (needPickup && (!pickupAddress || !pickupCity || !pickupLandmark)) {
      notification.error({
        message: 'Validation Error',
        description: 'Please fill in all pickup details.',
      });
      return;
    }
  
    // Ensure user agrees to terms and conditions
    if (!isAgreed) {
      notification.error({
        message: 'Agreement Required',
        description: 'You must agree to the terms and conditions.',
      });
      return;
    }
  
    const bookingData = {
      fullName,
      email,
      phone,
      specialRequests,
      paymentMethod,
      paymentAddress,
      isSubscribed,
      subscriptionEmail: isSubscribed ? email : '',
      needPickup,
      pickupDetails: needPickup
        ? {
            pickupAddress,
            pickupCity,
            pickupLandmark,
          }
        : null,
      tripDetails: {
        ...finalDetails,
        totalPrice: totalPrice,
      },
    };
  
    try {
      // Open a blank window to avoid pop-up blocking
      const paymentWindow = window.open('', '_blank');
      
      // Make the API call
      const response = await axiosInstance.post('/api/checkout/bookings', bookingData);
  
      // Redirect the blank window to the payment URL
      if (paymentWindow) {
        paymentWindow.location.href = response.data.paymentUrl;
      } else {
        // If the pop-up failed to open, notify the user
        notification.error({
          message: 'Popup Blocked',
          description: 'Please allow pop-ups in your browser to complete the payment process.',
        });
      }
  
      setIsModalVisible(false);
      notification.success({
        message: 'Booking Confirmed',
        description: 'Your booking has been confirmed successfully.',
      });
    } catch (error) {
      console.error('Error submitting booking:', error);
      notification.error({
        message: 'Booking Failed',
        description: 'There was an error confirming your booking. Please try again.',
      });
    }
  };
  
  

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const days = finalDetails && finalDetails.startDate && finalDetails.endDate
    ? Math.round((new Date(finalDetails.endDate) - new Date(finalDetails.startDate)) / (1000 * 60 * 60 * 24)) + 1
    : 0;

  
    const convertAmount = (amount) => {

      if (localStorage.getItem('selectedCurrency') === 'USD') {
  
        localStorage.setItem('currentFormat', 'USD')
  
        return `$ ${(amount * localStorage.getItem('eurToUsd')).toFixed(2)}`
  
      } else {
  
        localStorage.setItem('currentFormat', 'EUR')
  
        return `€ ${(amount * localStorage.getItem('usdToEur')).toFixed(2)}`
  
      }
    }

  return (
    <main>
      <SecondaryHeader />
      <section className="p-5 md:p-8">
        <h1 className="text-2xl md:text-3xl font-semibold mb-5">Confirm Your Booking</h1>
        <div className="flex flex-col md:flex-row gap-10">

          {/* Trip Summary */}
          {finalDetails ? (
            <div className="md:w-6/12 bg-white p-5 rounded-3xl shadow-md flex flex-col justify-between h-fit">
              <div className=' relative '>
                {finalDetails.mainphoto  ? (
                  <img src={`https://tripcurascobackend-production.up.railway.app/${finalDetails.mainphoto}`} alt={finalDetails.name} className="w-full h-64 object-cover rounded-lg mb-3" />
                ) : (
                  <p>No images available</p>
                )}
                <div className=' bg-white p-2 rounded-md absolute top-2 right-2'>
                  <p className="text-gray-800 font-light flex items-center justify-center gap-1">{finalDetails.rating}
                    <img src="../../assets/svg/star.svg" alt="" />
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">{finalDetails.name}</h2>
              </div>
              <p className="text-gray-500">{finalDetails.desc}</p>
              <p className="text-gray-500 font-light mb-4">
                {finalDetails.location}
              </p>
              <div className="w-full flex flex-col gap-2">
                <div className="flex justify-between items-center">
                  <p className="text-lg font-medium text-gray-800">{convertAmount(finalDetails.price)}</p>
                  <p className="text-gray-800 flex justify-center items-center gap-3">
                    {/* {formattedStartDate} - {formattedEndDate} */}

                  </p>
                </div>
                <div className="flex w-full justify-between text-gray-900 font-light">
                  <p>member(s)</p>
                  <p>{finalDetails.members}</p>
                </div>
                {/* <div className="flex w-full justify-between text-gray-900 font-light">
                  <p>Days</p>
                  <p>{days} days</p>
                </div> */}
                <div className="flex w-full justify-between text-gray-900 font-light">
                  <p>Discount ({finalDetails.discountPercentage}%)</p>
                  <p> {convertAmount(finalDetails.discount)}</p>
                </div>
                <div className="flex w-full justify-between text-gray-900 font-light">
                  <p>Service fee ({finalDetails.serviceFeePercentage}%)</p>
                  <p> {convertAmount(finalDetails.serviceFee)}</p>
                </div>
                {needPickup && finalDetails.pickup && (
                  <div className="flex w-full justify-between text-gray-900 font-light">
                    <p>Pickup Fee</p>
                    <p> {convertAmount(finalDetails.pickupPrice)}</p>
                  </div>
                )}
                <span className="bg-gray-200 w-full" style={{ height: '1px' }}></span>
                <div className="flex w-full justify-between text-gray-800 text-xl font-bold">
                  <p>Total payable</p>
                  <p> {convertAmount(totalPrice)}</p>
                </div>
              </div>
            </div>
          ) : (
            <p>No trip details available</p>
          )}

          {/* Customer Details Form */}
          <div className="md:w-6/12">
            <div className='bg-white p-5 rounded-lg shadow-md h-fit'>
              <h2 className="text-xl font-semibold mb-3">Your Details</h2>
              <Input
                className="mb-3"
                size="large"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <Input
                className="mb-3"
                size="large"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="flex space-x-3 mb-3">
                <PhoneInput
                  country={'us'} // Default country
                  value={phone}
                  onChange={setPhone}
                  inputStyle={{
                    width: '100%',
                    height: '40px',
                    fontSize: '16px'
                  }}
                  containerStyle={{ width: '100%' }}
                />
              </div>
              <Input.TextArea
                className="mb-3"
                size="large"
                placeholder="Special Requests"
                value={specialRequests}
                onChange={(e) => setSpecialRequests(e.target.value)}
                rows={4}
              />
              {finalDetails && finalDetails.pickup && (
                <>
                  <div className="flex items-center mb-3">
                    <span className="mr-3">Need Pickup?</span>
                    <Switch checked={needPickup} onChange={(checked) => setNeedPickup(checked)} />
                  </div>
                  {needPickup && (
                    <div className="space-y-3">
                      <Input
                        className="mb-3"
                        size="large"
                        placeholder="Pickup Address"
                        value={pickupAddress}
                        onChange={(e) => setPickupAddress(e.target.value)}
                      />
                      <Input
                        className="mb-3"
                        size="large"
                        placeholder="City"
                        value={pickupCity}
                        onChange={(e) => setPickupCity(e.target.value)}
                      />
                      <Input
                        className="mb-3"
                        size="large"
                        placeholder="Landmark"
                        value={pickupLandmark}
                        onChange={(e) => setPickupLandmark(e.target.value)}
                      />
                    </div>
                  )}
                </>
              )}

            </div>
            <div className='bg-white p-5 rounded-lg shadow-md h-fit mt-5'>
              <h2 className="text-xl font-semibold mb-3">Payment Details</h2>
              <Select
                className="mb-3 w-full"
                size="large"
                placeholder="Select Payment Method"
                value={paymentMethod}
                onChange={(value) => setPaymentMethod(value)}
              >
                <Option value="" className='opacity-35'>Select Payment Method</Option>
                <Option value="Pay nl">Pay nl</Option>
              </Select>
              <Input
                className="mb-3"
                size="large"
                placeholder="Billing Address"
                value={paymentAddress}
                onChange={(e) => setPaymentAddress(e.target.value)}
              />

              <div className=' flex flex-col mt-2'>
                <Checkbox
                  checked={isAgreed}
                  onChange={(e) => setIsAgreed(e.target.checked)}
                >
                  I agree to the terms and conditions <a onClick={handleConfirmBooking} className=' underline text-blue-500'>Read terms and conditions</a>
                </Checkbox>
                <Checkbox
                  checked={isSubscribed}
                  onChange={(e) => setIsSubscribed(e.target.checked)}
                >
                  Get emails with updates and special offers
                </Checkbox>
              </div>
              <Button type="primary" size="large" onClick={handleModalOk} className="w-full mt-3">
                Confirm Booking
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Terms and Conditions */}
      <Modal
        title="Terms and Conditions"
        visible={isModalVisible}
        onOk={handleModalOk}
        width={1000}
        onCancel={handleModalCancel}
        className=' text-gray-600 text-xs'
      >
        <p>
          Welcome to TheTripCuraçao. By accessing our website and booking our services, you agree to the following terms and conditions. If you do not agree with any part of these terms, please do not use www.thetripcuracao.com.
        </p>
        <p>
          <strong>Service Description:</strong> TheTripCuraçao offers a variety of travel and tour services. TheTripCuraçao may modify, improve, or discontinue services at its discretion without notice. Services may also be provided through subsidiaries or affiliated entities.
        </p>
        <p>
          <strong>User/Customer:</strong> The user/customer is the person who has booked via the platform. The customer who makes the reservation guarantees that they are authorized to make a reservation for the group they are bringing.
        </p>
        <p>
          <strong>Third Parties:</strong> A third party is a recognized company or person that offers a service on behalf of TheTripCuraçao or a provider.
        </p>
        <p>
          <strong>Booking and Payment:</strong> All bookings must be made through our website (www.thetripcuracao.com). Full payment is required at the time of booking unless otherwise stated. We accept major credit cards and other online payment systems for your convenience.
        </p>
        <p>
          <strong>Changes and Modifications:</strong> TheTripCuraçao reserves the right to change or modify these terms at any time. Users are advised to review these terms periodically to stay informed of any updates.
        </p>
        <p>
          <strong>User Responsibilities:</strong> Users are expected to adhere to the following guidelines while using TheTripCuraçao:
          <ol>
            <li>Users must provide accurate information during the booking process and comply with all local laws and regulations.</li>
            <li>Users must not make bookings under someone else's name or impersonate another person.</li>
            <li>Users must not approach other users for commercial purposes unrelated to the intended use of the Website.</li>
            <li>If you wish to report another user for misuse of the Website, please send an email to info@naarcuracao.com.</li>
            <li>TheTripCuraçao reserves the right to edit or remove information provided by users before it is made public or published on the Website.</li>
          </ol>
        </p>
        <p>
          <strong>Liability:</strong> TheTripCuraçao is not liable for any damages, losses, or injuries that occur during the use of our services. Users participate in activities at their own risk. We highly recommend taking appropriate precautions and following all safety guidelines.
        </p>
        <p>
          <ol>
            <li><strong>Use of Third Parties:</strong> TheTripCuraçao reserves the right to engage third parties, such as tour operators, to provide certain services as needed. TheTripCuraçao is not obligated to personally notify users of such engagements. By using TheTripCuraçao's services, you authorize TheTripCuraçao to accept any liability limitations of these third parties on your behalf. Additionally, TheTripCuraçao does not directly participate in the agreements made between users and tour operators and is therefore not responsible for any arrangements made between them. In case of a conflict, users must resolve it directly with the tour operator. TheTripCuraçao may attempt to mediate but is not a party that can be held liable.</li>
            <li>While TheTripCuraçao may engage various third parties found through available channels, we do not guarantee the performance of these third parties. TheTripCuraçao is not liable for the actions or omissions of these third parties, except in cases of intentional misconduct or gross negligence by TheTripCuraçao.</li>
            <li><strong>Direct Damage:</strong> TheTripCuraçao is only liable for direct damage caused by gross negligence or willful misconduct by TheTripCuraçao, up to the maximum amount of the invoice or the deposit made through TheTripCuraçao, whichever is higher.</li>
            <li><strong>Information Accuracy:</strong> TheTripCuraçao is not liable for damage resulting from any action or omission based on information available on the website or linked websites, even if there are inaccuracies.</li>
            <li><strong>Website Functionality:</strong> TheTripCuraçao is not responsible for errors or irregularities in the functionality of the Website and is not liable for any downtime or unavailability of the Website for any duration.</li>
          </ol>
        </p>
        <p>
          <strong>Complaints:</strong> Your satisfaction is important to us. If you are not satisfied with our services, you can file a complaint in the following ways:
          <ol>
            <li><strong>Customer Service:</strong> Use the contact form on our website, provide all necessary information and attachments. We will respond within one business day.</li>
            <li><strong>Email:</strong> Send your complaint to info@thetripcuracao.com with all relevant details and attachments. We value your feedback and aim to address any complaints promptly. Complaints submitted to TheTripCuraçao will be responded to within 5 days from the date of receipt, provided the complaint is received within 5 days after the activity has taken place. The right to a (partial) refund or compensation will be forfeited if the complaint is not submitted within the specified period, unless the nature of the case warrants a longer period.</li>
          </ol>
        </p>
        <p>
          <strong>Intellectual Property Rights:</strong>
          <ol>
            <li><strong>Source Code:</strong> It is not permitted to modify, create derivative works from, decompile, or otherwise access or alter the source code of the Website, except when expressly permitted by an open-source license or with explicit written permission. Any attempt to do so is a violation of TheTripCuraçao's rights.</li>
            <li><strong>Trademarks:</strong> The name TheTripCuraçao, along with the logo, are used as trademarks of TheTripCuraçao and may not be copied, imitated, or used, in whole or in part, without prior written permission. Additionally, headings, graphics, button icons, and scripts are service marks, trademarks, and/or trade dress of TheTripCuraçao and may not be copied, imitated, or used without prior written permission.</li>
          </ol>
        </p>
        <p>
          <strong>Invalidity:</strong> If any provision of these Terms and Conditions is found to be invalid or unenforceable, TheTripCuraçao and the user will remain bound by all remaining provisions. TheTripCuraçao will replace the invalid or unenforceable provision(s) with a valid and enforceable provision that, to the greatest extent possible, achieves the intended effect of the original provision(s), considering the purpose of these Terms and Conditions.
        </p>
        <p>
          <strong>Refund Policy:</strong> At TheTripCuraçao, we aim to provide you with the best travel experience possible. Here’s how our refund process works:
          <ol>
            <li><strong>Refund Method:</strong> Refunds will be processed using the same payment method used for the reservation or by voucher, at our discretion. Refunds will be sent to the billing address or account provided to us.</li>
            <li><strong>Accommodation and Rental Cars:</strong> Please note that we do not offer refunds for early check-outs from accommodations or early returns of rental cars.</li>
            <li><strong>Marketplace Role:</strong> Since we act as a marketplace, facilitating bookings between you and the service providers, any issues regarding refunds, early returns, or extensions should be addressed directly with the respective accommodation, car rental, or tour operator.</li>
            <li><strong>Tour Bookings:</strong> If you need a refund for a tour booking, we will contact the tour operator on your behalf. If the tour operator agrees to the refund, we will process it for you. Please understand that refunds can only be issued if the tour operator provides us with the refund amount.</li>
            <li><strong>Late Return:</strong> For rental cars, late returns may incur additional charges based on the provider’s policies. These charges are typically calculated on an hourly basis and rounded up to the next 15-minute interval. It is important to return the car on time to avoid any extra fees.</li>
            <li>If another customer has reserved the accommodation or car immediately after your booking and the late return results in a loss for the service provider, you may be held responsible for the lost rental time as per the provider’s terms.</li>
            <li><strong>Contact for Issues:</strong> If you encounter any issues or need assistance with refunds or returns, please contact the accommodation or car rental provider directly. We recommend reviewing their specific policies to understand your responsibilities and any potential charges. While TheTripCuraçao can offer guidance and support, we are not obligated to resolve disputes, as these matters should be handled between you and the service provider.</li>
          </ol>
        </p>
        <p>
          <strong>Cancellations and Amendments:</strong> If you need to cancel or amend your booking, please follow these steps:
          <ol>
            <li><strong>Cancellation Requests:</strong> All cancellation requests must be sent to and confirmed by info@thetripcuracao.com.</li>
            <li><strong>Refund Policy:</strong> You can cancel your tour with a full refund up to 24 hours prior to the tour.</li>
            <li><strong>No Show Policy:</strong> Please note that no-shows will not be refunded. NO SHOW = NO REFUND!</li>
          </ol>
          These regulations apply to all tour bookings facilitated through TheTripCuraçao.
        </p>
        <div className=' flex flex-col mt-6'>
          <Checkbox
            checked={isAgreed}
            onChange={(e) => setIsAgreed(e.target.checked)}
          >
            I agree to the terms and conditions
          </Checkbox>
          <Checkbox
            checked={isSubscribed}
            onChange={(e) => setIsSubscribed(e.target.checked)}
          >
            Get emails with updates and special offers
          </Checkbox>
        </div>
      </Modal>

    </main>
  );
};

export default Checkout;
