import React from 'react'
import { useState } from "react";

function BadgeDetails() {
  const badges = [
    {
      label: 'Cultural ambassador',
      icon: '../../assets/svg/allbadges/cultural.svg',
      desc: 'Celebrated for offering deep cultural immersion and authentic local experiences, providing a true sense of the destination.'
    },
    {
      label: 'Photographers dream',
      icon: '../../assets/svg/allbadges/photographer.svg',
      desc: 'Awarded for its breathtaking scenery and exceptional photo opportunities, making it a paradise for photographers.'
    },
    {
      label: 'Top rated',
      icon: '../../assets/svg/allbadges/top.svg',
      desc: 'Consistently receives the highest ratings from travelers who praise its quality and memorable experiences.'
    },
    {
      label: 'Local favorite',
      icon: '../../assets/svg/allbadges/local.svg',
      desc: 'Loved and highly recommended by local residents, offering a genuine taste of the area\'s best attractions.'
    },
    {
      label: 'Sells out fast',
      icon: '../../assets/svg/allbadges/sells.svg',
      desc: 'Known for its high demand and popularity, with bookings filling up quickly.'
    },
    {
      label: 'Group fun',
      icon: '../../assets/svg/allbadges/group.svg',
      desc: 'Renowned for delivering exceptional and enjoyable experiences tailored for group activities.'
    },
    {
      label: 'Eco friendly',
      icon: '../../assets/svg/allbadges/eco.svg',
      desc: 'Recognized for its commitment to sustainable practices and environmentally friendly tourism.'
    }
  ];
  
  // Active badges for the trip
  const activeBadges = ['Top rated'];
  
  return (
    <div>
    {badges.map((badge, index) => (
      <div key={index} className={`flex flex-col md:flex-row items-center mb-2 p-2 ${activeBadges.includes(badge.label) ? 'border border-blue-500 rounded-2xl' : 'opacity-50'}`}>
        <img src={badge.icon} alt={badge.label} className="mr-2" />
        <p className={`text-center text-sm md:text-left font-light md:w-9/12 text-gray-700 ${activeBadges.includes(badge.label) ? 'opacity-100' : 'opacity-50'}`}>
          {badge.desc}
        </p>
      </div>
    ))}
  </div>
  )
}

export default BadgeDetails
