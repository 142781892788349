import React, { useState, useEffect } from 'react';
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Input, Select, AutoComplete, DatePicker, message, Modal } from 'antd';
import AddTripModal from '../../Components/AddTripModal';
import { axiosInstance } from '../../API/authapi';
import TripModal from '../../Components/TripModal';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


const { Option } = Select;

const Trips = () => {
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddTripModalVisible, setIsAddTripModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOption, setFilterOption] = useState('all');
  const [sortOption, setSortOption] = useState('none');
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [trips, setTrips] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTrips, setTotalTrips] = useState(0);

  const [blockedDates, setBlockedDates] = useState([]);

  const [blockdates, setBlockdates] = useState({});

  useEffect(() => {
    axiosInstance.get('/api/home')
      .then(response => {
        // Convert the blocked dates from the response to JavaScript Date objects
        const blockedDates = response.data[0].blockeddate.map(date => new Date(date));
        setBlockedDates(blockedDates);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const navigate = useNavigate();

  const handleDateChange = (date) => {
    if (date) {
      const dateObj = new Date(date); // Ensure date is a Date object
      Modal.confirm({
        title: 'Confirm Block Date',
        content: `Are you sure you want to block ${dateObj.toDateString()}?`,
        onOk: () => {
          axiosInstance.post('/api/trips/block-date', { dates: dateObj.toISOString() })
            .then((response) => {
              setBlockedDates([...blockedDates, dateObj]);
              message.success('Date blocked successfully');
            })
            .catch((error) => {
              message.error('Error blocking date');
            });
        },
      });
    }
  };

  const handleUnblockDate = (dateToRemove) => {
    const dateObj = new Date(dateToRemove); // Ensure dateToRemove is a Date object
    Modal.confirm({
      title: 'Confirm Unblock Date',
      content: `Are you sure you want to unblock ${dateObj.toDateString()}?`,
      onOk: () => {
        axiosInstance.post('/api/trips/unblock-date', { date: dateObj.toISOString() })
          .then((response) => {
            setBlockedDates(blockedDates.filter(date => date.getTime() !== dateToRemove.getTime()));
            message.success('Date unblocked successfully');
          })
          .catch((error) => {
            message.error('Error unblocking date');
          });
      },
    });
  };

  const disabledDate = (current) => {
    const currentDate = new Date(current); // Ensure current is a Date object
    return blockedDates.some(date => date.toDateString() === currentDate.toDateString());
  };



  const fetchTrips = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/trips/admintrips', {
        params: {
          page: currentPage,
          limit: 8,
          search: searchTerm,
          filter: filterOption,
          sort: sortOption
        }
      });

      setTrips(response.data.trips);
      setTotalPages(response.data.totalPages);
      setTotalTrips(response.data.totalTrips); // Add this line
    } catch (error) {
      console.error('Error fetching trips:', error);
      if (error.response?.status === 401) {
        localStorage.clear();
        navigate('/admin/login');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrips();
  }, [currentPage, searchTerm, filterOption, sortOption]);


  const showBookingDetails = (booking) => {
    setSelectedBooking(booking);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedBooking(null);
  };

  const handleSaveBooking = (updatedBooking) => {
    if (updatedBooking) {
      // Update the existing trip
      const updatedBookings = bookings.map((booking) =>
        booking._id === updatedBooking._id ? updatedBooking : booking
      );
      setBookings(updatedBookings);
    } else {
      // If updatedBooking is null, it means the booking was deleted
      setBookings(bookings.filter((booking) => booking._id !== selectedBooking._id));
    }
    setIsModalVisible(false);
    setSelectedBooking(null); // Clear the selected booking
  };


  const handleSearchChange = (value) => {
    setSearchTerm(value);
    const filteredOptions = bookings
      .filter(booking => booking.tripname.toLowerCase().includes(value.toLowerCase()))
      .map(booking => ({ value: booking.tripname }));
    setAutoCompleteOptions(filteredOptions);
  };

  const filteredBookings = bookings
    .filter(booking =>
      booking.tripname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      booking.location.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(booking => {
      if (filterOption === 'all') return true;
      if (filterOption === 'sold') return booking.sold > 0;
      if (filterOption === 'immediateBooking') return booking.immediate_booking;
      return true;
    });

  const sortedBookings = filteredBookings.sort((a, b) => {
    if (sortOption === 'none') return 0;
    if (sortOption === 'priceHighLow') return b.price - a.price;
    if (sortOption === 'priceLowHigh') return a.price - b.price;
    if (sortOption === 'soldHighLow') return b.sold - a.sold;
    if (sortOption === 'soldLowHigh') return a.sold - b.sold;
    return 0;
  });



  const handleAddTrip = () => {
    setIsAddTripModalVisible(true);
  };

  const handleSaveNewTrip = (newTrip) => {
    setBookings([...bookings, { ...newTrip, id: bookings.length + 1 }]);
    setIsAddTripModalVisible(false);
  };

  const handleCloseAddTripModal = () => {
    setIsAddTripModalVisible(false);
  };



  const addBlockedDate = (date) => {
    if (date && !blockedDates.some(d => d.getTime() === date.toDate().getTime())) {
      setBlockedDates([...blockedDates, date.toDate()]);
    }
  };



  // Create an array with _id and tripname
  const bookingIdentifiers = bookings.map(booking => ({
    _id: booking._id,
    tripname: booking.tripname
  }));

  return (
    <main className='ml-52 pl-3 pr-3'>
      <header className='w-full pt-10'>
        <h1 className='text-gray-800 font-medium text-xl'>Admin Controls</h1>
        <div className='flex justify-between w-full items-center'>
          <div className='flex gap-2'>
            <AutoComplete
              options={autoCompleteOptions}
              style={{ width: 200 }}
              onSelect={(value) => setSearchTerm(value)}
              onSearch={handleSearchChange}
            >
              <Input.Search placeholder="Search Activity" />
            </AutoComplete>
            <Select
              placeholder="Filter"
              onChange={(value) => setFilterOption(value)}
              className='w-40'
            >
              <Option value="all">All</Option>
              <Option value="sold">Sold</Option>
              <Option value="immediateBooking">Immediate Booking</Option>
            </Select>
            <Select
              placeholder="Sort By"
              onChange={(value) => setSortOption(value)}
              className='w-40'
            >
              <Option value="none">None</Option>
              <Option value="priceHighLow">Price: High to Low</Option>
              <Option value="priceLowHigh">Price: Low to High</Option>
              <Option value="soldHighLow">Sold: High to Low</Option>
              <Option value="soldLowHigh">Sold: Low to High</Option>
            </Select>
          </div>
          <button
            className='bluebutton p-2 pl-4 pr-4 rounded-sm text-white text-sm'
            onClick={handleAddTrip}
          >
            Add trip
          </button>
        </div>
      </header>


      <section className='mt-6 mb-6'>
        <div>
          <h1 className='text-gray-800 font-medium text-xl'>Block Dates</h1>
          <DatePicker
            className='mt-3'
            disabledDate={disabledDate} // Disables blocked dates
            onChange={handleDateChange}  // Trigger date blocking action
            dateRender={(current) => {
              const currentDate = new Date(current); // Ensure current is a Date object
              const style = {};
              if (blockedDates.some(date => date.toDateString() === currentDate.toDateString())) {
                style.border = '2px solid red';
                style.backgroundColor = '#ffe6e6';
                style.color = 'red';
              }
              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              );
            }}
          />

          {blockedDates.length > 0 && (
            <div className='mt-4 relative'>
              <h4>Blocked Dates:</h4>
              <ul className='grid grid-cols-5 gap-2 mt-2'>
                {blockedDates.map((date, index) => (
                  <li
                    key={index}
                    className='border text-red-500 border-red-400 bg-white bg-opacity-40 px-3 py-2 rounded-lg flex justify-between'
                  >
                    {date.toDateString()}
                    <button onClick={() => handleUnblockDate(date)}>
                      <CloseCircleOutlined />
                    </button>
                  </li>
                ))}
              </ul>
              <div className='absolute left-0 right-0 top-full h-px bg-gray-300 opacity-50 mt-4'></div>
            </div>
          )}
        </div>
      </section>

      <section className=' mt-6'>
        <div className=' grid grid-cols-4 gap-4 mt-2 text-gray-700'>
          {trips.map((booking) => (
            <div key={booking._id} className='bg-white rounded-xl shadow-sm overflow-hidden p-2 cursor-pointer' onClick={() => showBookingDetails(booking)}>
              <div className='relative'>
                <div className='bg-white p-2 text-theblue-400 rounded-lg shadow-lg absolute text-sm font-bold left-1 top-1'>
                  #{booking.id}
                </div>
                <img src={`https://tripcurascobackend-production.up.railway.app/${booking.mainphoto}`} alt={booking.tripname} className='rounded-md w-full h-40 object-cover' />
                <div className='p-2 flex justify-between items-center relative'>
                  <h3 className='text-sm font-medium w-3/4'>{booking.tripname}</h3>
                  {/* <EditOutlined className='text-lg cursor-pointer text-gray-600' /> */}
                  <p className=' text-theblue-400 font-bold'>${booking.price}</p>
                </div>
              </div>
            </div>

          ))}
        </div>
        {/* Pagination Controls */}
        <div className='flex flex-col items-center space-y-2 mt-4 mb-5'>
          <div className='flex justify-center items-center space-x-2'>
            <button
              onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
              disabled={currentPage === 1}
              className='px-3 py-1 rounded-md bg-gray-200 text-gray-700 disabled:opacity-50'
            >
              Previous
            </button>

            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPage(index + 1)}
                className={`px-3 py-1 rounded-md ${currentPage === index + 1
                  ? 'bg-theblue-400 text-white'
                  : 'bg-gray-200 text-gray-700'
                  }`}
              >
                {index + 1}
              </button>
            ))}

            <button
              onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
              disabled={currentPage === totalPages}
              className='px-3 py-1 rounded-md bg-gray-200 text-gray-700 disabled:opacity-50'
            >
              Next
            </button>
          </div>

          <div className='text-gray-600'>
            Total Trips: {totalTrips} | Page {currentPage} of {totalPages}
          </div>
        </div>
      </section>





      {selectedBooking && (
        <TripModal
          visible={isModalVisible}
          booking={selectedBooking}
          onClose={handleCloseModal}
          onSave={handleSaveBooking}
          bookingIdentifiers={bookingIdentifiers}
        />
      )}
      <AddTripModal
        visible={isAddTripModalVisible}
        onClose={handleCloseAddTripModal}
        onSave={handleSaveNewTrip}
        bookingIdentifiers={bookingIdentifiers}  // Pass the array to AddTripModal
      />
    </main>
  );
};

export default Trips;
